import React from 'react';
import invoiceDetails from '../../mockdata/invoiceDetails';
import StatusChip from '../../components/StatusChip';
import { dateFn } from '../../utils/dateTimeFormatter';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { amountCheckFn, numberCheckFn, textCheckFn } from '../../utils/commonUtil';
import { useNavigate } from 'react-router-dom';

export default function InvoiceDetails({ invoice, invoiceLoading }) {
  const navigate = useNavigate();
  return (
    <div>
      {invoiceLoading ? (
        <Loader text='loading invoice details...' classes='h-96 ' bg={true} />
      ) : !invoice ? (
        <NoData text='No invoice found' classes='h-96' />
      ) : (
        <React.Fragment>
          <div className='mb-4'>
            <ul className='inline-flex flex-wrap text-sm font-medium'>
              <li className='flex items-center'>
                <a
                  className='go-link-16'
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/account', { state: { from: 'invoice' } });
                  }}
                >
                  Home
                </a>
                <svg className='text-client-link mx-1 h-4 w-4 fill-current' viewBox='0 0 16 16'>
                  <path d='M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z' />
                </svg>
              </li>
              <li className='flex items-center'>
                <a
                  className='go-link-16'
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/account', { state: { from: 'invoice' } });
                  }}
                >
                  Payments
                </a>
              </li>
            </ul>
          </div>
          <div className='flex'>
            <h3 className='go-textblackbold-24 mr-3'>Invoice Details</h3>
            {invoice?.status === 'BAD_DEBT' ? (
              <StatusChip status={'PAST_DUE'} />
            ) : (
              <StatusChip status={invoice?.status && invoice?.status} />
            )}
          </div>
          <div className='flex'>
            <p className='go-textblack-16 py-1'>Invoice {invoice?.transaction_id && invoice?.transaction_id}</p>
          </div>
          <div className='my-5 flex space-x-4'>
            <div className='bg-client-tileBg1 sm:min-w-60 rounded-lg p-3 '>
              {/* <div>
                <span className='go-textblacknormal-14 opacity-70'>Sent On:</span>
                <span className='go-textblack-14  pl-2'>
                  {invoice?.sent_date && dateFn('MM/DD/YYYY', invoice?.sent_date)}
                </span>
              </div> */}
              <div>
                <span className='go-textblacknormal-14  opacity-70'>Due On:</span>
                <span className='go-textblack-14  pl-2'>
                  {invoice?.due_date && dateFn('MM/DD/YYYY', invoice?.due_date)}
                </span>
              </div>
            </div>
            <div className='bg-client-tileBg2 sm:min-w-60 rounded-lg p-3 '>
              <div>
                <span className='go-textblacknormal-14  opacity-70'>Total:</span>
                <span className='go-textblack-14  pl-2'>{amountCheckFn(invoice?.balance)}</span>
              </div>
              <div>
                <span className='go-textblacknormal-14  opacity-70'>Remaining Balance:</span>
                <span className='go-textblack-14  pl-2'>{amountCheckFn(invoice?.remaining_balance)}</span>
              </div>
            </div>
          </div>
          <div className='z-0 rounded-lg bg-white '>
            <header className='flex items-center border-b border-gray-100 p-4 pl-5'>
              <h5 className='go-textnormal-18 my-1 flex-1'>Billing Details</h5>
            </header>
            <div className='flex p-6'>
              <table className='hidden w-full sm:block'>
                <tbody>
                  <tr>
                    <td rowSpan={3} className='go-label-14 w-1/6 p-1.5 text-right align-top'>
                      Billing Address
                    </td>
                    <td rowSpan={3} className='go-textblack-14 w-1/6 p-1.5 pl-3 text-left align-top'>
                      {invoice?.billing_address?.full_address} {numberCheckFn(invoice?.billing_address?.postal_code)}
                    </td>
                    <td className='go-label-14 w-1/6 p-1.5 text-right align-top'>Billing Frequency</td>
                    <td className='go-textblack-14 w-1/6 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.invoice_frequency)}
                    </td>
                    <td className='go-label-14 w-1/6 p-1.5 text-right align-top'>Paying Via</td>
                    <td className='go-textblack-14 w-1/6 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.default_payment_method?.type)}
                    </td>
                  </tr>

                  {invoice?.billing_address?.billing_details?.invoice_day_of_week && (
                    <tr>
                      <td rowSpan={2} className='go-label-14 p-1.5 text-right align-top'>
                        Billing Day
                      </td>
                      <td rowSpan={2} className='go-textblack-14 p-1.5 pl-3 text-left align-top'>
                        {textCheckFn(invoice?.billing_address?.billing_details?.invoice_day_of_week)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className='go-label-14 p-1.5 text-right align-top'>Paying Terms</td>
                    <td className='go-textblack-14 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.payment_terms)}
                    </td>
                  </tr>
                  {/* <tr>
                    <td className='go-label-14 p-1.5 text-right align-top'>Sales Tax</td>
                    <td className='go-textblack-14 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.sales_tax_status)}
                    </td>
                  </tr> */}
                </tbody>
              </table>
              <table className='w-full sm:hidden'>
                <tbody>
                  <tr>
                    <td className='go-label-14 w-1/6 p-1.5 text-right align-top'>Billing Address</td>
                    <td className='go-textblack-14 w-1/6 p-1.5 pl-3 text-left align-top'>
                      {invoice?.billing_address?.full_address} {numberCheckFn(invoice?.billing_address?.postal_code)}
                    </td>{' '}
                  </tr>
                  <tr>
                    <td className='go-label-14 w-1/6 p-1.5 text-right align-top'>Billing Frequency</td>
                    <td className='go-textblack-14 w-1/6 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.invoice_frequency)}
                    </td>{' '}
                  </tr>
                  <tr>
                    <td className='go-label-14 w-1/6 p-1.5 text-right align-top'>Paying Via</td>
                    <td className='go-textblack-14 w-1/6 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.default_payment_method?.type)}
                    </td>
                  </tr>
                  {invoice?.billing_address?.billing_details?.invoice_day_of_week && (
                    <tr>
                      <td className='go-label-14 p-1.5 text-right align-top'>Billing Day</td>
                      <td className='go-textblack-14 p-1.5 pl-3 text-left align-top'>
                        {textCheckFn(invoice?.billing_address?.billing_details?.invoice_day_of_week)}
                      </td>{' '}
                    </tr>
                  )}
                  <tr>
                    <td className='go-label-14 p-1.5 text-right align-top'>Paying Terms</td>
                    <td className='go-textblack-14 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.payment_terms)}
                    </td>
                  </tr>
                  {/* <tr>
                    <td className='go-label-14 p-1.5 text-right align-top'>Sales Tax</td>
                    <td className='go-textblack-14 p-1.5 pl-3 text-left align-top'>
                      {textCheckFn(invoice?.billing_address?.billing_details?.sales_tax_status)}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
