import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import StatusChip from '../../../components/StatusChip';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import ClientButton from '../../../components/ClientButton';
import { Link } from 'react-router-dom';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import { deactivateAcccountApi } from '../../../redux/Reducers/commonApis';
import Loader from '../../../components/Loader';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { toast } from 'react-hot-toast';
import { add, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactivateModal from '../../../components/ReactivateModal';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { accountTypeFormatFn, textCheckFn } from '../../../utils/commonUtil';
import ServiceAddressModal from '../../CreateNewOrder/PickupAddress/ServiceAddressModal';
import PhoneNumbersList from '../../../components/PhoneNumbersList';

export default function MemberCard({ setEdit, data, setAddMember }) {
  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Members</h5>
          {!isEmpty(data?.members) && (
            <ClientButton variant='primary' text='Add Member' size='xs' onClick={() => setAddMember(true)} />
          )}
          {!isEmpty(data?.members) && (
            <div className='flex'>
              {!isEmpty(data?.members) && (
                <button className='go-btn-primary mr-2'>
                  <EditIcon
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </button>
              )}
            </div>
          )}
        </header>
        {!isEmpty(data?.members) ? (
          data?.members?.map((member, index) => (
            <div
              key={index}
              className={'p-6' + (index == data?.members?.length - 1 ? '' : ' border-b border-gray-100')}
            >
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-textblacknormal-16 font-semibold'>
                  {textCheckFn(member?.first_name)} {textCheckFn(member?.last_name)}
                </div>
              </div>
              {!isEmpty(member?.phone_numbers) && (
                <div className='mb-4 flex-row space-y-1.5'>
                  <PhoneNumbersList phoneNumbers={member?.phone_numbers && member?.phone_numbers} />
                </div>
              )}
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Email</div>
                <div className='go-textblacknormal-16 font-medium'>{member?.email ? `${member?.email}` : '-'}</div>
              </div>
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Assigned Addresses</div>
                {member?.addresses?.map((address, index1) => (
                  <div className='go-textblacknormal-16 font-medium' key={index1}>
                    {address?.name} at {address?.full_address}
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className={'p-6'}>
            <ClientButton variant='primary' text='Add Member' size='xs' onClick={() => setAddMember(true)} />
          </div>
        )}
      </div>
    </div>
  );
}
