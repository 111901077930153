import React, { useCallback, useState } from 'react';
import orderbg from '../../../images/inactive_account_graphic.svg';
import ReactivateModal from '../../../components/ReactivateModal';
export default function InActiveAccount({ data }) {
  const [activateModal, setActivateModal] = useState(false);
  const showReactivateModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setActivateModal(val);
  }, []);
  return (
    <div className='grow '>
      <div className='w-full rounded-xl bg-white'>
        <div className='justify-center p-8 sm:p-20 xl:flex'>
          <img src={orderbg} alt='' />
        </div>
        {data?.account_type == 'ORGANIZATION_MEMBER' ? (
          <div className='go-text-semibold-16 p-3 pb-24 text-center'>
            Your account is Inactive <br></br> Please contact your Account Admin to restore
          </div>
        ) : (
          <div className='go-text-semibold-18 p-3 text-center'>Your account is Inactive</div>
        )}

        {data?.account_type != 'ORGANIZATION_MEMBER' && (
          <div className='mt-8 flex justify-center px-12 pb-10 text-center sm:pb-40'>
            <button
              className='go-textwhite-15 w-3/4 cursor-pointer rounded-full bg-[#548ff7] py-3 text-center lg:hidden xl:block'
              onClick={(e) => {
                showReactivateModalFn(e, true);
              }}
            >
              Activate Your Account
            </button>
          </div>
        )}
      </div>
      <ReactivateModal activateModal={activateModal} setActivateModal={setActivateModal} />
    </div>
  );
}
