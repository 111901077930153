import React, { useState } from 'react';
import { selectedServices } from '../../../mockdata/common';
import PaymentMethodAndPreferences from './PaymentMethodAndPreferences';
import ServicesEstimate from '../OrderSummary/ServicesEstimate';

export default function PaymentMethod() {
  const [availableServices, setAvailableServices] = useState(['Wash and Fold', 'Dry Cleaning', 'Ironing']);
  const [services, setServices] = useState(selectedServices);
  const addService = () => {
    setServices([...services, { id: services.length + 1, quantity: 1 }]);
  };
  return (
    <div>
      <div className='max-w-9xl mx-auto w-full'>
        <div className='grid grid-cols-12 gap-4 sm:gap-6'>
          <PaymentMethodAndPreferences
            services={services}
            addService={addService}
            availableServices={availableServices}
          />
          <ServicesEstimate services={services} />
        </div>
      </div>
    </div>
  );
}
