import React, { useState } from 'react';
import lc_default_promo from '../images/lc-promo-default.png';
import lc_default_promo_mobile from '../images/lc-promo-default-mobile.png';
import pl_default_promo from '../images/pl-promo-default.png';
import pl_default_promo_mobile from '../images/pl-promo-default-mobile.png';
import { useNavigate } from 'react-router-dom';
const tenant = import.meta.env.VITE_TENANT_NAME;
function PromoCard() {
  const navigate = useNavigate();
  return (
    <div
      className={` w-full cursor-pointer rounded-xl bg-white transition-[50] md:transition-[72] `}
      onClick={() => {
        navigate('/create-new-order?type=subscription');
      }}
    >
      <>
        <div
          className={`xs:flex relative hidden flex-row items-center overflow-hidden rounded-xl !bg-cover !bg-no-repeat p-5`}
          style={{
            background: `url(${tenant === 'laundrycare' ? lc_default_promo : pl_default_promo})`,
          }}
        >
          <div className='flex items-center justify-start'>
            <div className='ml-2 w-full flex-row items-center text-white sm:w-3/5'>
              {tenant === 'laundrycare' ? (
                <p className='text-xl font-bold leading-8 sm:text-4xl sm:leading-10'>
                  Automate Your Laundry With A Subcription
                </p>
              ) : (
                <p className='text-2xl font-bold capitalize leading-8 sm:text-3xl sm:leading-10'>
                  Put Your Laundry On Auto Pilot.
                </p>
              )}
              <p className=' mb-3 text-xl font-bold leading-8 sm:text-4xl sm:leading-10 '></p>
              <p className='pb-5 text-base sm:text-xl'>
                Never run out of clean undies.
                <br />
                Start a subscription today!
              </p>
            </div>
          </div>
        </div>
        <div
          className={`xs:hidden relative flex-row items-center overflow-hidden rounded-xl !bg-cover !bg-no-repeat p-5`}
          style={{
            background: `url(${tenant === 'laundrycare' ? lc_default_promo_mobile : pl_default_promo_mobile})`,
          }}
        >
          <div className='flex items-center justify-start'>
            <div className='ml-2 w-full flex-row items-center text-center text-white sm:w-3/5'>
              {tenant === 'laundrycare' ? (
                <p className='text-xl font-bold leading-8 sm:text-4xl sm:leading-10'>
                  Automate Your Laundry With A Subcription
                </p>
              ) : (
                <p className='text-2xl font-bold capitalize leading-8 sm:text-3xl sm:leading-10'>
                  Put Your Laundry On Auto Pilot.
                </p>
              )}
              <p className=' mb-3 text-xl font-bold leading-8 sm:text-4xl sm:leading-10 '></p>
              <p className='text-base sm:text-xl'>
                Never run out of clean undies.
                <br />
                Start a subscription today!
              </p>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default PromoCard;
