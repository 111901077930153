import React, { useCallback, useState } from 'react';
import ModalBasic from '../../../components/Modal';
import SingleMember from './SingleMember';
import EditIcon from '../../../components/Icons/EditIcon';
import ClientButton from '../../../components/ClientButton';

import AddIcon from '../../../components/Icons/AddIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import ModalWithHeading from '../../../components/ModalWithHeading';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';
import { updateProfileApi } from '../../../redux/Reducers/MyAccountReducer';
import { useDispatch } from 'react-redux';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';

export default function EditMemberModal({ modalOpen, setModalOpen, data, member }) {
  const dispatch = useDispatch();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [selectedMemberToDelete, setSelectedMemberToDelete] = useState(null);
  const deleteModalFn = useCallback((e, val, member) => {
    e.stopPropagation();
    setdeleteModal(val);
    setSelectedMemberToDelete(member);
  }, []);
  const deleteMemberFn = () => {
    setDeleteLoading(true);
    const payload = { members: [{ id: selectedMemberToDelete?.id, operation: 'DELETE' }] };
    const create = updateProfileApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setDeleteLoading(false);
        toast.success('Member deleted successfully!');
        setdeleteModal(false);
        setSelectedMemberToDelete(null);
        dispatch(getProfileDataApi(false));
      } else {
        setDeleteLoading(false);
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };
  return (
    <>
      {' '}
      <ModalBasic
        id='edit-member-modal'
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={'Edit Member'}
        width='md'
      >
        <SingleMember
          key={member?.id}
          member={member}
          deleteModalFn={deleteModalFn}
          data={data}
          setEdit={setModalOpen}
        />
      </ModalBasic>{' '}
      <ModalWithHeadingSmall
        modalOpen={deleteModal}
        setModalOpen={setdeleteModal}
        title={`Are you sure you want to remove ${selectedMemberToDelete?.first_name}?`}
        classes='max-w-2xl py-10'
      >
        <div className='mx-8'>
          {deleteLoading ? <Loader text='processing...' classes='h-44 ' /> : null}
          <div className='flex-col justify-center py-2 text-center '>
            <ClientButton
              variant='secondary'
              text='Cancel'
              classes=''
              onClick={() => {
                setdeleteModal(false);
              }}
            />
            <ClientButton
              variant='cancel'
              text='Yes'
              classes=''
              onClick={() => {
                deleteMemberFn();
              }}
            />
          </div>
        </div>
      </ModalWithHeadingSmall>
    </>
  );
}
