import React, { useState } from 'react';
import TableFilter from '../../../components/TableFilter';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import StatusChip from '../../../components/StatusChip';
import EditIcon from '../../../components/Icons/EditIcon';
import ClientButton from '../../../components/ClientButton';
export default function ServiceAddressesTable({ addressess, setNewAddressModalOpen, setActiveTab, selectedAddressId }) {
  const [loading, setLoading] = useState(false);
  return (
    <div className='mt-6'>
      <div className=' rounded-lg bg-white'>
        <header className='flex items-center justify-between p-5 pb-2'>
          <div>
            <h5 className='go-textblackmedium-18 my-1 flex-1 font-semibold'>Service Address</h5>
            <div className='flex items-center'>
              <span className='mr-2'>Edit All</span>
              <EditIcon
                onClick={() => {
                  setActiveTab('ServiceAddress');
                }}
              />
            </div>
          </div>
          <ClientButton variant='primary' text='Add Address' size='xs' onClick={() => setNewAddressModalOpen(true)} />
        </header>
        <div className=''>
          <div className=' min-h-auto overflow-x-auto shadow-sm'>
            <table className='w-full table-auto divide-y divide-slate-200 '>
              <thead className='border-b border-slate-200'>
                <tr>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                    <TableFilter text='Name' align='text-left' sort={false} />
                  </th>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                    <TableFilter text='Address' align='text-left' sort={false} />
                  </th>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                    <TableFilter text='Status' align='text-center' sort={false} />
                  </th>
                  <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5} className='py-10 text-center'>
                      <Loader text='loading service addresses...' classes='h-96' />
                    </td>
                  </tr>
                ) : addressess?.length === 0 ? (
                  <tr>
                    <td colSpan={5} className='py-10 text-center'>
                      <NoData text='No service address found' />
                    </td>
                  </tr>
                ) : (
                  addressess?.map((address, idx) => {
                    return (
                      <tr className={`cursor-pointer ${idx % 2 ? '' : 'bg-slate-50'}`} key={idx}>
                        <td
                          className={
                            'go-textblack-15 whitespace-nowrap px-2 py-2 align-top first:pl-5 last:pr-5' +
                            (!address?.is_address_in_active_service_area && ' opacity-50')
                          }
                        >
                          {address?.name}
                        </td>
                        <td
                          className={
                            'go-textblack-15 whitespace-nowrap px-2 py-2 align-top first:pl-5 last:pr-5' +
                            (!address?.is_address_in_active_service_area && ' opacity-50')
                          }
                        >
                          {address?.full_address}
                        </td>
                        <td className='whitespace-nowrap px-2 py-2 align-top first:pl-5  last:pr-5'>
                          <StatusChip status={address?.is_address_in_active_service_area ? 'Active' : 'Inactive'} />
                        </td>
                        <td className='go-text-semibold-15  w-1/5 justify-items-end whitespace-nowrap px-2 py-2 align-top first:pl-5  last:pr-5'>
                          {address?.is_address_in_active_service_area && (
                            <EditIcon
                              onClick={() => {
                                setNewAddressModalOpen(true);
                                selectedAddressId(address?.id);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
