import React, { useEffect, useState } from 'react';
import servicesAndPricingData from '../../mockdata/servicesandpricing';
import icon from '../../images/clothes.png';
import DropdownSingleAddress from '../../components/DropdownSingleAddress';
import { useSelector } from 'react-redux';
import { getServicesAndPricingApi } from '../../redux/Reducers/commonApis';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { toast } from 'react-hot-toast';
import { amountCheckFn } from '../../utils/commonUtil';
import { isEmpty } from 'lodash';
import NoDataHtml from '../../components/NoDataHtml';
import NewServiceAddressModal from '../CreateNewOrder/PickupAddress/NewServiceAddressModal';

function ServiceAddressDropdown({ allServiceAddresses, selectedAddress }) {
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    if (allServiceAddresses) {
      const locations = allServiceAddresses.map((address) => ({
        id: address.id,
        value: `${address?.name}, ${address?.address_line_1}`,
      }));
      setAllLocations(locations);
    }
  }, [allServiceAddresses]);

  return <DropdownSingleAddress dropdownValues={allLocations} newItem={false} />;
}

function ServiceItem({ item }) {
  return (
    <div className='col-span-full rounded-lg bg-white xl:col-span-4'>
      <div className='flex h-full flex-col p-5'>
        <div className='grow'>
          <header className='mb-4 flex justify-between space-x-3'>
            <div className=''>
              <h3 className='text-base font-semibold text-black'>{item?.name}</h3>
              <div className='bodyText'>{item?.description}</div>
            </div>
            <div className=''>
              <img src={item?.image?.absolute_url || icon} alt='image' className='' />
            </div>
          </header>
        </div>
        <footer className='mt-4'>
          <div className='flex flex-wrap items-center justify-between'>
            <div className='text-sm font-semibold'>Price</div>
            <span className='text-sm font-semibold'>{amountCheckFn(item?.client_fee)}</span>
          </div>
          {item?.priority_fee > 0 && (
            <div className='flex flex-wrap items-center justify-between text-[#334155]'>
              <div className='text-sm'>with Priority Delivery</div>
              <span className='text-sm'>+{amountCheckFn(item?.priority_fee)}</span>
            </div>
          )}
          <div className='flex flex-wrap items-center justify-between '>
            <div className='text-sm font-semibold'></div>
            <span className='text-sm font-semibold'>per {item?.unit_type}</span>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default function ServicesAndPricing() {
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedAddress) {
      setLoading(true);
      const response = getServicesAndPricingApi(selectedAddress?.id);
      response.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setData(res?.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.message || 'Something went wrong');
        }
      });
    }
  }, [selectedAddress]);

  return (
    <div className=''>
      {!isEmpty(allServiceAddresses) && (
        <div className='bg-white'>
          <div className='sm:max-w-9xl relative flex w-full items-center px-4 pb-4 sm:mx-auto sm:px-6'>
            <div className='go-heading-18 w-full font-medium sm:w-auto'>
              Service Address -
              <ServiceAddressDropdown allServiceAddresses={allServiceAddresses} selectedAddress={selectedAddress} />
            </div>
          </div>
        </div>
      )}
      {!isEmpty(allServiceAddresses) ? (
        <div className='sm:max-w-9xl w-full p-4 sm:mx-auto sm:p-6'>
          {loading ? (
            <Loader text='loading services and pricing details...' classes='h-96' />
          ) : data?.length === 0 || data == null ? (
            <NoData text='No services are available at this address' classes='h-96' />
          ) : (
            <div className='grid grid-cols-12 gap-4 sm:gap-6'>
              {data?.map((item, idx) => (
                <ServiceItem key={idx} item={item} />
              ))}
            </div>
          )}
        </div>
      ) : (
        <NoDataHtml>
          <div className=' w-full px-4 py-8 sm:px-6 lg:px-8'>
            <div className=' text-center'>
              You have no service addresses associated with your account. Please add one to get started.
            </div>
            <div className='m-auto  mt-5 max-w-5xl rounded-md border border-slate-300 bg-white p-5'>
              <NewServiceAddressModal cancel={false} />
            </div>
          </div>
        </NoDataHtml>
      )}
    </div>
  );
}
