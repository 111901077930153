import React, { useEffect, useState } from 'react';
import activeaccount from '../images/account-active-success.svg';
import Loader from '../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import ModalWithHeadingSmall from '../components/ModalWithHeadingSmall';
import { activateAcccountApi } from '../redux/Reducers/commonApis';
import GreenCheckMarkIcon from '../components/Icons/GreenCheckMarkIcon';
import { getProfileDataApi } from '../redux/Reducers/ProfileReducer';
import { useDispatch } from 'react-redux';
import { set } from 'lodash';
import Button from './Button';

export default function ReactivateModal({ activateModal, setActivateModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activateMsg, setActivateMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [withMembers, setWithMembers] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const activateApiCall = (payload) => {
    setLoading(true);
    const response = activateAcccountApi(payload);
    response?.then((res) => {
      if (res?.status === 400) {
        setLoading(false);
        setActivateMsg(res?.data?.message || 'Something went wrong');
        if (res?.data?.members?.length > 0) {
          setMembers(res?.data?.members);
          setWithMembers(true);
        } else {
          setWithMembers(false);
        }
      } else if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setLoading(false);
        setWithMembers(false);
        setActivateMsg(res?.message || 'Your account is now Active' || 'Something went wrong');

        setTimeout(() => {
          dispatch(getProfileDataApi(true));
        }, 1000);
      } else {
        setLoading(false);
        setActivateModal(false);
        setActivateMsg(res?.message || 'Something went wrong');
      }
    });
  };
  const memberSaveClickFn = () => {
    const payload = {
      warning_confirmation: true,
      member_ids: selectedMembers || [],
    };
    activateApiCall(payload);
  };
  useEffect(() => {
    if (activateModal) {
      const payload = {};
      activateApiCall(payload);
    }
  }, [activateModal]);

  //   const closeModalFn = () => {
  //     dispatch(getProfileDataApi());
  //     setActivateModal(false);
  //   };
  return (
    <div>
      <ModalWithHeadingSmall
        modalOpen={activateModal}
        setModalOpen={setActivateModal}
        icon={!withMembers && <GreenCheckMarkIcon />}
        title={activateMsg}
        classes='max-w-2xl py-8'
      >
        {loading ? (
          <Loader text='loading...' classes='h-40 w-full' />
        ) : (
          <div>
            {!withMembers ? (
              <div className='max-w-9xl mx-auto w-full px-24'>
                <div className='   justify-center p-20 xl:flex'>
                  <img src={activeaccount} alt='' />
                </div>
              </div>
            ) : (
              <div className='max-w-9xl mx-auto w-full px-24'>
                <div className='flex-row py-4 px-10'>
                  {members?.map((item, idx) => (
                    <div className='my-4 flex items-center justify-between' key={idx}>
                      <div className='go-textblack-16 my-1.5'>
                        {item?.first_name} {item?.last_name}
                      </div>
                      <div className='form-switch'>
                        <input
                          type='checkbox'
                          id={item?.id}
                          className='sr-only'
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedMembers((prev) => [...prev, item?.id]);
                            } else {
                              setSelectedMembers((prev) => prev.filter((i) => i !== item?.id));
                            }
                          }}
                        />
                        <label className='bg-slate-400' htmlFor={item?.id}>
                          <span className='bg-white shadow-sm' aria-hidden='true'></span>
                          <span className='sr-only'>Switch label</span>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {/* <ClientButton variant='secondary' text='Close' size='small' onClick={() => setActivateModal(false)} />
            <ClientButton
              variant='primary'
              text='Create New Order'
              size='small'
              onClick={() => navigate('/create-new-order')}
            /> */}
        {withMembers && (
          <div className='flex space-x-4 p-5'>
            <Button variant='secondary' text='Cancel' classes='w-full' onClick={() => setActivateModal(false)} />
            <Button variant='primary-dark' text='Save' classes='w-full' onClick={() => memberSaveClickFn()} />
          </div>
        )}
      </ModalWithHeadingSmall>
    </div>
  );
}
