import React, { useEffect, useState } from 'react';

import Services from './Services';
import ServicesEstimate from '../OrderSummary/ServicesEstimate';
import { getServicesAndPricingApi } from '../../../redux/Reducers/commonApis';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderSummary } from '../../../redux/Reducers/CreateOrderReducer';
import { toast } from 'react-hot-toast';
import { isEmpty } from 'lodash';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';

export default function SelectServices() {
  const dispatch = useDispatch();
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const { selectedServices } = useSelector((state) => state.createNewOrder);
  const [availableServices, setAvailableServices] = useState([]);

  const [servicesLoading, setServicesLoading] = useState(false);

  const getServicesAndPricingFn = (id) => {
    setServicesLoading(true);
    const response = getServicesAndPricingApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const services = res?.data?.map((service) => {
          return {
            service: service?.name,
            id: service?.id,
            unit: service?.unit_type,
            client_fee: service?.client_fee,
            priotity_fee: service?.priority_fee,
            description: service?.description,
            image: service?.image?.absolute_url || null,
          };
        });
        setServicesLoading(false);
        setAvailableServices(services || []);
        dispatch(setOrderDataAction({ availableServices: services }));
      } else {
        setServicesLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    selectedAddress && getServicesAndPricingFn(selectedAddress?.id);
  }, [selectedAddress]);

  return (
    <div>
      <div className='max-w-9xl mx-auto w-full'>
        <div className='grid grid-cols-12 gap-4 sm:gap-6'>
          <Services availableServices={availableServices} servicesLoading={servicesLoading} />
          <ServicesEstimate />
        </div>
      </div>
    </div>
  );
}
