import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { loginApi, loginValdiateEmailApi } from '../../utils/axios';
import { useParams, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDataApi } from '../../redux/Reducers/ProfileReducer';
import { getAllServiceAddressesApi } from '../../redux/Reducers/ServiceAddressesReducer';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { getGoogleAuthUrl, signinThroughGoogle } from '../../redux/Reducers/commonApis';
import passwordEye from '../../../src/images/passwordeye.png';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';
function SigninPage() {
  const tenant = import.meta.env.VITE_TENANT_NAME;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailObject, setEmailObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('processing...');
  const [screenType, setScreenType] = useState('login');
  const [mainText, setMainText] = useState('Log In');
  const [loginBtnText, setLoginBtnText] = useState('Continue');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState(
    'We couldn’t find an account with that email. Please double-check your email address or try a different one.'
  );
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    data = {
      email: data?.email.toLowerCase(),
      ...data,
    };
    if (!isEmpty(data) && screenType === 'login') {
      setEmailObject({ email: data?.email });
      setLoading(true);
      const result = loginValdiateEmailApi(data, tenant);
      result?.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoading(false);
          if (res?.data?.data?.email == data?.email) {
            setEmailObject(res?.data?.data);
            setScreenType('password');
            setLoginBtnText('Log In');
          }
        } else if (res?.status === 400 || res?.status === 401 || res?.status === 403 || res?.status === 404) {
          setLoading(false);
          setInvalidEmail(true);
          setInvalidEmailMsg(res?.message || 'Something went wrong');
        }
      });
    } else if (screenType === 'password') {
      setLoading(true);
      const result = loginApi(data, tenant);
      result?.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoadingMsg('fetching profile data...');
          const getProfileData = dispatch(getProfileDataApi(true));
          getProfileData?.then((res) => {
            if (res?.status === 200 || res?.status === 201) {
              const data = dispatch(getAllServiceAddressesApi());
              data?.then((resVal) => {
                if (resVal?.status === 200 || resVal?.status === 201) {
                  if (!isEmpty(res?.data?.past_due_invoices)) {
                    window.location.href = '/account';
                  } else {
                    navigate('/dashboard');
                  }
                }
              });
            } else if (res?.status === 400 || res?.status === 401 || res?.status === 403) {
              setLoading(false);
              toast.error(res?.message || 'Something went wrong');
            } else {
              setLoading(false);
              toast.error(res?.message || 'Something went wrong');
            }
          });
        } else if (res?.status === 400 || res?.status === 401 || res?.status === 403) {
          if (res?.message) {
            setLoading(false);
            setInvalidPassword(true);
          }
        }
      });
    }
  };
  useEffect(() => {
    if (screenType === 'login') {
      setMainText('Log In');
    } else if (screenType === 'resetpassword') {
      setMainText('Reset Password');
    }
  }, [screenType]);
  const signInWithGoogle = () => {
    const authCode = getGoogleAuthUrl(tenant, window.location.href);
    authCode?.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        window.location.href = res?.google_oauth_url;
      }
    });
  };
  useEffect(() => {
    const tempUrl = new URL(window.location.href);
    const tempUrlParams = new URLSearchParams(tempUrl.search);
    const tempState = tempUrlParams.get('state');
    const tempCode = tempUrlParams.get('code');
    if (tempUrl?.searchParams?.size > 3) {
      if (tempState && tempCode) setLoading(true);
      setLoadingMsg('verifying...');
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      if (code) {
        const tempCode = {
          auth_code: code,
        };
        const tempDomain = new URL(window.location.href);
        const token = signinThroughGoogle(tenant, tempCode, `${tempDomain?.origin}/signin`);
        token?.then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            setLoadingMsg('fetching profile data...');
            const getProfileData = dispatch(getProfileDataApi(true));
            getProfileData?.then((res) => {
              if (res?.status === 200 || res?.status === 201) {
                const data = dispatch(getAllServiceAddressesApi());
                data?.then((resVal) => {
                  if (resVal?.status === 200 || resVal?.status === 201) {
                    if (!isEmpty(res?.data?.past_due_invoices)) {
                      window.location.href = '/account';
                    } else {
                      navigate('/dashboard');
                    }
                  }
                });
              } else if (res?.status === 400 || res?.status === 401 || res?.status === 403) {
                setLoading(false);
                toast.error(res?.message || 'Something went wrong');
              } else {
                setLoading(false);
                toast.error(res?.message || 'Something went wrong');
              }
            });
          }
        });
      }
    }
  }, []);
  return (
    <div className='mt-10 sm:mt-24'>
      <div className='mx-auto w-full px-4 pt-8 sm:w-[400px]'>
        {screenType != 'resetpassword' && screenType != 'newpassword' && (
          <h1
            className={
              `text-center text-2xl font-bold text-slate-800 ` +
              (screenType == 'password' || screenType == 'login' ? 'mb-2' : 'mb-4')
            }
          >
            {mainText}
          </h1>
        )}
        {(screenType === 'password' || screenType == 'login') && (
          <div className='mb-6 flex justify-center'>
            <div className='mr-1'>
              <Link
                className='text-sm font-semibold text-[#548ff7]'
                to='#'
                onClick={() => {
                  if (tenant == 'laundrycare') {
                    window.open('https://www.laundrycare.biz/signup/', '_blank');
                  } else {
                    window.open('https://www.pushlaundry.com/signup/', '_blank');
                  }
                }}
              >
                Don't have an account? Sign Up
              </Link>
            </div>
          </div>
        )}
        <form className='mb-4 '>
          <div className='space-y-4'>
            {screenType === 'login' && (
              <div className='mb-6'>
                {invalidEmail ? (
                  <label className='go-textnormal-14 text-red-500' htmlFor='email'>
                    * Email
                  </label>
                ) : (
                  <label className='go-label-15' htmlFor='email'>
                    Email
                  </label>
                )}

                <input
                  id='email'
                  className={`go-inputtext-15 w-full ${errors?.email?.type === 'required' ? 'border-red-500' : ''}`}
                  placeholder='Enter your email'
                  type='email'
                  name='email'
                  required
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Please enter your email address',
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Invalid email address',
                    },
                    onChange: (e) => {
                      setInvalidEmail(false);
                    },
                  })}
                  autoComplete='off'
                />
                {errors.email?.message && <p className='go-textnormal-14 text-red-500'>{errors.email?.message}</p>}
                {invalidEmail && (
                  <p
                    className='go-textnormal-14 mb-4 text-red-500'
                    dangerouslySetInnerHTML={{ __html: invalidEmailMsg }}
                  />
                )}
              </div>
            )}
            {screenType === 'password' && (
              <>
                <div className='relative'>
                  <label className=' go-label-15' htmlFor='email2'>
                    Email
                  </label>
                  <div className='flex justify-between'>
                    <span>{emailObject?.email}</span>
                    <Link
                      className='text-sm font-semibold text-[#548ff7]'
                      to='#'
                      onClick={() => {
                        setScreenType('login');
                        setInvalidPassword(false);
                        resetField('password');
                      }}
                    >
                      Change
                    </Link>
                  </div>
                </div>
                <div className='relative mb-1'>
                  {invalidPassword ? (
                    <label className='go-textnormal-14 text-red-500' htmlFor='password'>
                      * Password
                    </label>
                  ) : (
                    <label className=' go-label-15' htmlFor='password'>
                      Password
                    </label>
                  )}
                  <input
                    id='password'
                    className={`go-inputtext-15 w-full ${
                      errors?.password?.type === 'required' ? 'border-red-500' : ''
                    }`}
                    placeholder='*********'
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    required
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Please enter your password',
                      },
                      onChange: (e) => {
                        setInvalidPassword(false);
                      },
                    })}
                    autoComplete='off'
                  />
                  <img
                    src={passwordEye}
                    alt='eye'
                    className='absolute  -right-1 top-5 mt-0.5 h-10 w-12'
                    onClick={() => setShowPassword(!showPassword)}
                  />
                  {errors.password?.message && (
                    <p className='go-textnormal-14 mb-4 text-red-500'>{errors.password?.message}</p>
                  )}
                  {invalidPassword && (
                    <p className='go-textnormal-14 mb-4 text-red-500'>{`The password you entered is incorrect. Please try again or select 'Forgot Password?'`}</p>
                  )}
                </div>
              </>
            )}
          </div>
          {screenType === 'password' && (
            <div className='mb-6 flex justify-end'>
              <div className='mr-1'>
                <Link
                  className='text-sm font-semibold text-[#548ff7]'
                  to='#'
                  onClick={() => {
                    setScreenType('resetpassword');
                    setInvalidPassword(false);
                    resetField('password');
                  }}
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
          )}
          {(screenType === 'password' || screenType === 'login') && (
            <div className='mb-6 border-slate-200'>
              <div className='flex items-center justify-between'>
                {/* <LinkButton
                  onClick={() => {
                    handleSubmit(onSubmit)();
                  }}
                  disabled={loading}
                  variant='primary'
                  text={loginBtnText}
                  loading={loading}
                /> */}
                <button
                  type='submit'
                  disabled={loading}
                  className={
                    'w-full rounded border border-[#548ff7] bg-[#548ff7] py-2 px-4 text-center text-sm  font-normal text-white' +
                    (loading ? ' pointer-events-none cursor-not-allowed opacity-80' : '')
                  }
                  onClick={handleSubmit(onSubmit)}
                  onsubmit='return false;'
                >
                  <div className='flex justify-center'>
                    {loading ? (
                      <svg className={'h-6 w-6 animate-spin stroke-white'} viewBox='0 0 256 256'>
                        <line
                          x1='128'
                          y1='32'
                          x2='128'
                          y2='64'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                        <line
                          x1='195.9'
                          y1='60.1'
                          x2='173.3'
                          y2='82.7'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                        <line
                          x1='224'
                          y1='128'
                          x2='192'
                          y2='128'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                        <line
                          x1='195.9'
                          y1='195.9'
                          x2='173.3'
                          y2='173.3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                        <line
                          x1='128'
                          y1='224'
                          x2='128'
                          y2='192'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                        <line
                          x1='60.1'
                          y1='195.9'
                          x2='82.7'
                          y2='173.3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                        <line
                          x1='32'
                          y1='128'
                          x2='64'
                          y2='128'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                        <line
                          x1='60.1'
                          y1='60.1'
                          x2='82.7'
                          y2='82.7'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='24'
                        ></line>
                      </svg>
                    ) : (
                      loginBtnText
                    )}
                  </div>
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
      {screenType === 'resetpassword' && (
        <ResetPassword setScreenType={setScreenType} setToken={setToken} emailObject={emailObject} />
      )}
      {screenType === 'newpassword' && <NewPassword token={token} setScreenType={setScreenType} />}{' '}
      {/* {screenType != 'newpassword' && (
        <div className='mx-auto w-full px-4 sm:w-[400px]'>
          <div className='mb-2 border-slate-200 pb-2'>
            <div className='flex w-full items-center justify-between'>
              <button
                aria-label='Sign in with Google'
                className='border-button-border-light flex w-full items-center justify-center rounded-md border bg-white p-0.5 pr-3'
                onClick={() => {
                  signInWithGoogle();
                }}
                disabled={loading}
              >
                <div className='space-between flex h-9 w-9 items-center justify-center rounded-l bg-white'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='h-5 w-5'>
                    <title>Log In / Sign Up with Google</title>
                    <desc>Google G Logo</desc>
                    <path
                      d='M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z'
                      className='fill-google-logo-blue'
                    ></path>
                    <path
                      d='M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z'
                      className='fill-google-logo-green'
                    ></path>
                    <path
                      d='M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z'
                      className='fill-google-logo-yellow'
                    ></path>
                    <path
                      d='M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z'
                      className='fill-google-logo-red'
                    ></path>
                  </svg>
                </div>
                <span className='text-google-text-gray text-sm tracking-normal'>Log In / Sign Up with Google</span>
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}
export default SigninPage;
