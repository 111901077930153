import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Addresses from './Addresses';
import NewAddress from './NewAddress';
import { getAllServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';
import ModalForOrders from './ModalForOrders';
import ModalToUpdateAddress from './ModalToUpdateAddress';

export default function ServiceAddresses({ newaddress, cancelNewAddress, setTabs }) {
  const dispatch = useDispatch();
  const { allServiceAddresses, allServiceAddressesLoading } = useSelector((state) => state.serviceAddresses);
  const [newAddressID, setNewAddressID] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [existingOrders, setExistingOrders] = useState(null);
  const [newAddressForOrders, setNewAddressForOrders] = useState(null);
  const [oldAddressForOrders, setOldAddressForOrders] = useState(null);

  const showUpdateModalFn = useCallback((e, val, res, type) => {
    e.stopPropagation();
    setShowUpdateModal(val);
    setNewAddressID({ id: res, type: type });
    // if(val === false) cancelNewAddress(res);
  }, []);
  const showOrderModalFn = useCallback((e, val, res, type, oldAddress, newAddress) => {
    e.stopPropagation();
    if (type === 'update') {
      setShowUpdateModal(val);
      setNewAddressID({ id: res, type: 'update' });
    } else {
      setShowOrderModal(val);
      setExistingOrders(res?.data);
      setNewAddressForOrders(newAddress);
      setOldAddressForOrders(oldAddress);
    }
  }, []);
  const onUpdateModalCancelFn = useCallback((val) => {
    setShowUpdateModal(false);
    cancelNewAddress(val);
  }, []);
  const onOrderModalCancelFn = useCallback((e, val, id, type) => {
    if (type === 'update') {
      setShowOrderModal(false);
      showUpdateModalFn(e, val, id, type);
    } else {
      setShowOrderModal(false);
      showUpdateModalFn(e, false, id);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllServiceAddressesApi());
  }, [!showUpdateModal]);

  // useEffect(() => {
  //   setserviceAddressess(allServiceAddresses);
  // }, [allServiceAddresses]);

  return (
    <>
      <div className='sm:max-w-9xl w-full px-4 sm:mx-auto sm:px-6 '>
        <ul className='mb-4 inline-flex flex-wrap text-sm font-medium'>
          <li className='flex items-center'>
            <a
              className='go-link-16'
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                setTabs('Profile');
              }}
            >
              Home
            </a>
            <svg className='text-client-link mx-1 h-4 w-4 fill-current' viewBox='0 0 16 16'>
              <path d='M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z' />
            </svg>
          </li>
          <li className='flex items-center'>
            <a
              className='go-link-16'
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                setTabs('Profile');
              }}
            >
              Profile
            </a>
          </li>
        </ul>
        {newaddress ? (
          <NewAddress showModal={showUpdateModalFn} cancel={onUpdateModalCancelFn} />
        ) : (
          <Addresses
            addresses={allServiceAddresses}
            loadingStatus={allServiceAddressesLoading}
            showModal={showOrderModalFn}
            setTabs={setTabs}
          />
        )}
        <div className='py-4'>
          <ModalToUpdateAddress
            modal={showUpdateModal}
            updateModal={setShowUpdateModal}
            newAddressID={newAddressID}
            addressess={allServiceAddresses}
            cancelModal={onUpdateModalCancelFn}
          />
          <ModalForOrders
            modal={showOrderModal}
            setShowOrderModal={setShowOrderModal}
            updateModal={onOrderModalCancelFn}
            existingOrders={existingOrders}
            newAddress={newAddressForOrders}
            oldAddress={oldAddressForOrders}
          />
        </div>
      </div>
    </>
  );
}
