import React from 'react';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { dateFn } from '../../utils/dateTimeFormatter';
import { amountCheckFn } from '../../utils/commonUtil';

export default function AssociatedOrders({ invoice, invoiceLoading }) {
  return (
    <div className='mt-4 rounded-lg bg-white sm:mt-6 '>
      {invoiceLoading ? (
        <Loader text='loading associated orders...' classes='h-96' />
      ) : invoice?.orders?.length == 0 ? (
        <NoData text='No associated orders found' classes='h-96' />
      ) : (
        <React.Fragment>
          <header className='flex items-center border-b border-gray-100 p-4 pl-5 align-top'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Associated Orders</h5>
            <div className='p-4'>
              <p className='go-textblack-12'>Address #1 </p>
              <p className='go-textblack-12'>{`${invoice?.billing_address?.full_address} ${invoice?.billing_address?.postal_code}`}</p>
            </div>
          </header>
          <div className='flex  overflow-scroll'>
            <table className='w-full divide-y divide-slate-200'>
              <thead className='border-b border-slate-200'>
                <tr>
                  <th className='go-textblack-12 p-4 px-8 text-left'>Number</th>
                  <th className='go-textblack-12 p-4 px-8 text-center'>Creation Date</th>
                  <th className='go-textblack-12 p-4 px-8 text-center'>Delivery Date</th>
                  <th className='go-textblack-12 p-4 px-8 text-right'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoice?.orders?.map((order, idx) => (
                  <tr key={idx}>
                    <td className='go-textblacknormal-13 p-4 px-8'>{order?.id}</td>
                    <td className='go-textblacknormal-13 p-4 px-8 text-center'>
                      {dateFn('MM/DD/YYYY', order?.created_date)}
                    </td>
                    <td className='go-textblacknormal-13 p-4 px-8 text-center'>
                      {dateFn('MM/DD/YYYY', order?.dropoff_date)}
                    </td>
                    <td className='go-textblacknormal-13 p-4 px-8 text-right'>{amountCheckFn(order?.final_total)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
