import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '../../../components/Icons/EditIcon';
import AddIcon from '../../../components/Icons/AddIcon';
import ClientButton from '../../../components/ClientButton';
import { getServicesAndPricingApi } from '../../../redux/Reducers/commonApis';
import DeleteMinus from '../../../components/Icons/DeleteMinus';
import { isEmpty, isNumber } from 'lodash';
import { toast } from 'react-hot-toast';
import { getOrderSummary } from '../../../redux/Reducers/CreateOrderReducer';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import { amountCheckFn, dollarAmountCheckFn, textCheckFn } from '../../../utils/commonUtil';
import { useNavigate } from 'react-router-dom';
import { dateFn } from '../../../utils/dateTimeFormatter';
import StatusChip from '../../../components/StatusChip';
import { titleCaseFormatter } from '../../../utils/stringFormatters';

export default function Services({ orderDetails, status, edit, setEdit, newOrderDetails, setNewOrderDetails }) {
  const { editOrderIsRush } = useSelector((state) => state.createNewOrder);
  const navigate = useNavigate();
  const [availableServices, setAvailableServices] = useState(null);
  const [updatedServices, setUpdatedServices] = useState([]);
  const [summary, setSummary] = useState({});
  const [calculationLoading, setCalculationLoading] = useState(false);
  const getServicesAndPricingFn = (id) => {
    const response = getServicesAndPricingApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const services = res?.data?.map((service) => {
          return { service: service?.name, id: service?.id, unit: service?.unit_type };
        });
        setAvailableServices(services || []);
      } else {
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const addService = () => {
    const availableServiceIds = availableServices?.map((service) => service?.id);
    const selectedServiceIds = updatedServices?.map((service) => service?.service_id);
    const diff = availableServiceIds?.filter((x) => !selectedServiceIds?.includes(x));
    const deletedItems = updatedServices
      ?.filter((item) => item?.operation === 'DELETE')
      .map((item) => item?.service_id);
    if (diff?.length > 0) {
      setUpdatedServices([...updatedServices, { service_id: diff[0], quantity: 1, operation: 'CREATE' }]);
    } else if (deletedItems?.length > 0) {
      const temp = [...updatedServices];
      const item = temp?.find((item) => item?.service_id === deletedItems[0]);
      item.operation = 'UPDATE';
      setUpdatedServices(temp);
    }
  };
  const onQuanityChangeFn = (id, val) => {
    const items = orderDetails?.items?.map((item) => item?.id);
    const isAvailable = items?.includes(id);
    if (isAvailable) {
      const temp = [...updatedServices];
      const item = temp?.find((item) => item?.service_id === id);
      item.quantity = +val;
      item.operation = 'UPDATE';
      setUpdatedServices(temp);
    } else {
      const temp = [...updatedServices];
      const item = temp?.find((item) => item?.service_id === id);
      item.quantity = +val;
      setUpdatedServices(temp);
    }
  };
  const onDeleteServiceFn = (id) => {
    const items = orderDetails?.items?.map((item) => item?.service?.id);
    const isAvailable = items?.includes(id);
    if (isAvailable) {
      const temp = [...updatedServices];
      const item = temp?.find((item) => item?.service_id === id);
      item.operation = 'DELETE';
      setUpdatedServices(temp);
    } else {
      const temp = [...updatedServices];
      const filtered = temp?.filter((item) => item?.service_id !== id);
      setUpdatedServices(filtered);
    }
  };
  const onChangeServiceFn = (e, id) => {
    const items = orderDetails?.items?.map((item) => item?.service?.id);
    const isAvailable = items?.includes(id);
    if (isAvailable) {
      const temp = [...updatedServices];
      const item = temp?.find((item) => item?.service_id === id);
      item.service_id = +e.target.value;
      item.operation = 'UPDATE';
      setUpdatedServices(temp);
    } else {
      const temp = [...updatedServices];
      const item = temp?.find((item) => item?.service_id === id);
      item.service_id = +e.target.value;
      setUpdatedServices(temp);
    }
  };
  const getOrderSummaryFn = () => {
    const items =
      updatedServices &&
      updatedServices?.map((service) => {
        if (service?.operation != 'DELETE') {
          return { service_id: service?.service_id, quantity: service?.quantity };
        }
      });
    const filtered = items?.filter((item) => item);
    const payload = {
      items: filtered || [],
      order_id: orderDetails?.id,
      address_id: orderDetails?.customer_address?.id,
      is_rush: editOrderIsRush?.is_rush,
    };
    if (!isEmpty(updatedServices)) {
      setCalculationLoading(true);
      const response = getOrderSummary(payload);
      response.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setCalculationLoading(false);
          setSummary(res?.data || {});
        } else {
          setCalculationLoading(false);
          toast.error(res?.message || 'Something went wrong');
        }
      });
    } else {
      setCalculationLoading(false);
    }
  };

  useEffect(() => {
    getOrderSummaryFn();
  }, [editOrderIsRush, orderDetails?.priority_fee]);

  const calculateOrderMinimumFn = (minimum, total) => {
    if (total < minimum) {
      const temp = minimum - total;
      return amountCheckFn(temp);
    }
  };

  useEffect(() => {
    orderDetails?.customer_address?.id && getServicesAndPricingFn(orderDetails?.customer_address?.id);
    const services = orderDetails?.items?.map((item) => {
      return {
        operation: 'EXISTING',
        id: item?.id,
        service_id: item?.service?.id,
        quantity: item?.quantity,
      };
    });
    setUpdatedServices(services || []);
  }, [orderDetails]);

  useEffect(() => {
    if (edit.orderServicesEdit) {
      const temp = [...updatedServices];
      const newItems = temp?.map((item) => {
        return item;
      });
      const filtered = newItems?.filter((item) => item);
      !isEmpty(filtered) && setNewOrderDetails({ ...newOrderDetails, items: filtered });
      {
        updatedServices?.filter((item) => item?.operation != 'DELETE').length != 0 && getOrderSummaryFn();
      }
    }
  }, [updatedServices, edit.orderServicesEdit]);

  return (
    <div className=' col-span-full flex flex-col sm:col-span-5 '>
      <div className='border-client-link mb-2 rounded-lg border bg-white p-5'>
        <header className='flex items-center pb-3'>
          <h5 className='go-heading-18 flex-1'>Services</h5>
          {status === 'WAITING' && orderDetails?.is_editable && (
            <div className='flex'>
              <button className='go-btn-primary'>
                <EditIcon
                  onClick={() => {
                    setEdit({
                      ...edit,
                      orderServicesEdit: !edit.orderServicesEdit,
                    });
                  }}
                />
              </button>
            </div>
          )}
        </header>
        {edit.orderServicesEdit ? (
          <div>
            <section className='my-2'>
              {updatedServices?.map((item, i) => {
                return (
                  item?.operation != 'DELETE' && (
                    <div className='flex justify-items-end py-4' key={i}>
                      <div className='w-6/12 pr-2'>
                        <label className='go-label-16 mb-1 block' htmlFor='label'>
                          {`Service ${i + 1}`}
                        </label>
                        <select
                          id='availableservices'
                          className='form-select go-textblacknormal-16 w-full'
                          onChange={(e) => onChangeServiceFn(e, item?.service_id)}
                          value={availableServices?.find((s) => s?.id === item?.service_id)?.id}
                        >
                          {availableServices?.map((elm, idx) => (
                            <option
                              key={idx}
                              value={elm?.id}
                              disabled={
                                updatedServices
                                  ?.filter((item) => item?.operation != 'DELETE')
                                  .find((item) => item?.service_id === elm?.id)
                                  ? true
                                  : false
                              }
                            >
                              {elm?.service}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='flex w-3/12 flex-row pl-2 align-bottom'>
                        <div>
                          <label className='go-label-16 mb-1 block' htmlFor='label'>
                            Quantity
                          </label>
                          <input
                            id='label'
                            className='form-input go-inputtext-16 w-full'
                            type='number'
                            onChange={(e) => onQuanityChangeFn(item?.service_id, e.target.value)}
                            defaultValue={item?.quantity}
                          />
                        </div>

                        <div className='go-label-14 mt-8 ml-2 font-semibold '>
                          {availableServices?.find((s) => s?.id === item?.service_id)?.unit}
                        </div>
                      </div>
                      <div className='w-2/12 pl-2'>
                        <label className='go-label-16 mb-1 block' htmlFor='label'>
                          Price
                        </label>
                        <span
                          id='label'
                          className={`go-textblacknormal-16 mt-1 block w-full items-center rounded-md p-2 ${
                            calculationLoading ? '' : 'bg-indigo-50'
                          }`}
                        >
                          {summary?.items?.find((service) => service?.id == item?.service_id)
                            ? `$${summary?.items?.find((service) => service?.id == item?.service_id)?.client_fee}`
                            : ''}
                        </span>
                      </div>
                      <div className=' pl-2'>
                        <label className='go-label-16 mb-1 block' htmlFor='label'>
                          &nbsp;
                        </label>
                        <div className='m-1 my-2'>
                          <DeleteMinus onClick={() => onDeleteServiceFn(item?.service_id)} />
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </section>
            {updatedServices?.filter((item) => item?.operation != 'DELETE').length < availableServices?.length && (
              <div>
                <hr className='go-hr-dashed '></hr>
                <div
                  className='my-5 flex cursor-pointer'
                  onClick={(e) => {
                    addService();
                  }}
                >
                  <div className='flex items-center'>
                    <AddIcon />
                    <a className='go-blue-16 ml-2' href='#'>
                      Add service
                    </a>
                  </div>
                </div>
              </div>
            )}
            <hr className='go-hr-dashed '></hr>
          </div>
        ) : (
          <table className='go-hr-dashed w-full table-auto'>
            <thead className='go-hr-dashed  text-xs text-gray-400'>
              <tr className='flex-inline'>
                <th width='70%'>
                  <div className=' go-label-16 py-3 text-left'>
                    <span>Service</span> <span className='pl-4'>Qty</span>
                  </div>
                </th>
                {/* <th width='10%'>
                  <div className=' go-label-16 py-3 text-right'>Qty</div>
                </th> */}
                <th width='20%'>
                  <div className=' go-label-16 py-3 text-right'>Pricing</div>
                </th>
              </tr>
            </thead>
            <tbody className=' text-sm'>
              {orderDetails?.items?.map((service, i) => (
                <tr
                  className={
                    'flex-inline  py-2  md:py-0 ' +
                    (i + 1 === orderDetails?.items?.length ? 'go-hr-dashed' : 'border-b border-slate-200')
                  }
                  key={i}
                >
                  <td className=' w-1/3 py-3 md:table-cell md:w-auto'>
                    <div className='go-text-16 text-left'>
                      {service?.name} ({service?.quantity})
                    </div>
                    <div className='text-left text-xs font-normal text-gray-500'>{service?.service?.description}</div>
                  </td>
                  {/* <td className=' w-1/3 py-3 md:table-cell md:w-auto'>
                    <div className='go-text-16 text-right'>{service?.quantity}</div>
                  </td> */}
                  <td className=' w-1/3 py-3 align-top md:table-cell md:w-auto'>
                    <div className='go-text-16 text-right'>{amountCheckFn(service?.price)}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {calculationLoading ? (
          <Loader text='loading summary...' classes='h-96' />
        ) : updatedServices?.filter((item) => item?.operation != 'DELETE').length == 0 ? (
          <NoData text='No service selected' classes='h-96' />
        ) : (
          <section className=' pt-3'>
            <ul>
              {orderDetails?.status == 'DELIVERED' && isNumber(orderDetails?.order_minimum_difference) && (
                <li className='go-hr-dashed mb-2 flex items-center justify-between py-1'>
                  <div className='go-label-16 mb-3'>Order Minimum:</div>
                  <div className='go-text-16 mb-3'>
                    <span>
                      {edit.orderServicesEdit ? (
                        <span>{calculateOrderMinimumFn(summary?.order_minimum, summary?.estimated_total_cost)}</span>
                      ) : (
                        <span>{amountCheckFn(orderDetails.order_minimum_difference)}</span>
                      )}
                    </span>
                  </div>
                </li>
              )}
              <li className=' flex items-center justify-between py-1'>
                <div className='go-label-16'>Subtotal:</div>
                <div className='go-text-16'>
                  {edit.orderServicesEdit ? (
                    <span>{amountCheckFn(summary?.subtotal)}</span>
                  ) : (
                    <span>{amountCheckFn(orderDetails?.subtotal)}</span>
                  )}
                </div>
              </li>
              <li className=' flex items-center justify-between py-1'>
                <div className='go-label-16'>
                  Promo code:
                  {status === 'DELIVERED' ? (
                    orderDetails?.final_discount_amount && <span>{orderDetails?.promo_code?.code}</span>
                  ) : (
                    <span>{orderDetails?.promo_code?.code}</span>
                  )}
                </div>

                <div className='go-text-16'>
                  {edit.orderServicesEdit ? (
                    <span>{textCheckFn(summary?.promo_code?.code)}</span>
                  ) : (
                    <span>
                      {status === 'DELIVERED'
                        ? amountCheckFn(orderDetails?.final_discount_amount)
                        : amountCheckFn(orderDetails?.estimated_discount_amount)}
                    </span>
                  )}
                </div>
              </li>
              {status === 'DELIVERED' && (
                <li className=' flex items-center justify-between py-1'>
                  <div className='go-label-16'>Sales Tax:</div>
                  <div className='go-text-16'>
                    <div className='go-text-16'>
                      <span>{amountCheckFn(orderDetails?.final_tax_cost)}</span>
                    </div>
                  </div>
                </li>
              )}
              {edit.orderPickupDeliveryEdit && editOrderIsRush?.is_rush && (
                <li className=' flex items-center justify-between py-1'>
                  <div className='go-label-16'>Priority Fee:</div>
                  <div className='go-text-16'>
                    <span>+{amountCheckFn(summary?.priority_fee)}</span>
                  </div>
                </li>
              )}
              {!edit.orderPickupDeliveryEdit && orderDetails?.priority_fee && (
                <li className=' flex items-center justify-between py-1'>
                  <div className='go-label-16'>Priority Fee:</div>
                  <div className='go-text-16'>
                    <span>+{amountCheckFn(orderDetails?.priority_fee)}</span>
                  </div>
                </li>
              )}
              <li className=' flex items-center justify-between py-1'>
                <div className='go-label-16'>Tip:</div>
                <div className='go-text-16'>
                  <div className='go-text-16'>
                    {edit.orderServicesEdit ? (
                      <span>{amountCheckFn(summary?.final_tip)}</span>
                    ) : (
                      <span>{amountCheckFn(orderDetails?.final_tip)}</span>
                    )}
                  </div>
                </div>
              </li>

              <li className=' flex items-center justify-between py-1'>
                <div className='go-label-16'>Service Fee:</div>
                <div className='go-text-16'>
                  {edit.orderServicesEdit ? (
                    <span>{amountCheckFn(summary?.service_fee)}</span>
                  ) : (
                    <span>{amountCheckFn(orderDetails?.service_fee)}</span>
                  )}
                </div>
              </li>
              <li className=' flex items-center justify-between py-3'>
                <div className='go-text-16'>
                  {orderDetails?.status == 'DELIVERED' ? 'Total Cost' : 'Estimated Total Cost'}
                </div>
                <div className='go-text-16'>
                  {edit.orderServicesEdit ? (
                    <span>{amountCheckFn(summary?.estimated_total_cost)}</span>
                  ) : (
                    <span>{amountCheckFn(orderDetails?.final_total)}</span>
                  )}
                </div>
              </li>
            </ul>

            {orderDetails?.order_minimum && orderDetails?.order_minimum > 0 && (
              <p className='text-sm text-blue-400'>
                We have a ${orderDetails?.order_minimum} minimum charge policy. <br></br> If your subtotal is less than
                ${orderDetails?.order_minimum} we will increase the subtotal to this amount.
              </p>
            )}
          </section>
        )}
      </div>
      {status === 'DELIVERED' && (
        <div className='border-client-link rounded-lg border bg-white p-5 '>
          <header className='flex items-center justify-between'>
            <h5 className='go-heading-18 flex-1'>Invoice #{orderDetails?.invoice?.transaction_id}</h5>
            {orderDetails?.invoice && <StatusChip status={orderDetails?.invoice?.status} />}
          </header>
          <div className='my-2 flex items-center justify-between'>
            <div className='go-text-16 flex-1 font-semibold text-blue-400'></div>
            <div>
              <span className='go-label-16'>Billing Frequency: </span>
              <span className='go-text-16'>
                {textCheckFn(orderDetails?.customer_address?.billing_details?.invoice_frequency)}
              </span>
            </div>
          </div>
          {!orderDetails?.invoice ? (
            <NoData text='No invoices found' classes='h-40' />
          ) : (
            <React.Fragment>
              <div className='flex py-3'>
                <table className='go-hr-dashed w-full table-auto'>
                  <thead className='go-hr-dashed  text-xs text-gray-400'>
                    <tr className='flex-inline'>
                      <th>
                        <div className=' go-label-16 py-3 text-left'>Title</div>
                      </th>
                      <th>
                        <div className=' go-label-16 py-3 text-right'>Date</div>
                      </th>
                      <th>
                        <div className=' go-label-16 py-3 text-right'>Amount</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className=' text-sm'>
                    {orderDetails?.invoice?.payments?.map((service, i) => (
                      <tr
                        className={'flex-inline go-hr-dashed  items-start  border-b border-slate-200 py-2 md:py-0'}
                        key={i}
                      >
                        <td className=' py-3 align-top md:table-cell md:w-auto'>
                          <div className='go-text-16 text-left'>
                            {titleCaseFormatter(service?.transaction?.transaction_type)}
                          </div>
                        </td>
                        <td className=' py-3 align-top md:table-cell  md:w-auto'>
                          <div className='go-text-16 hidden text-right sm:block'>
                            {dateFn('type1', service?.paid_date)}
                          </div>
                          <div className='go-text-16 text-right sm:hidden'>{dateFn('type2', service?.paid_date)}</div>
                          <div className='go-text-16 text-right sm:hidden'>{dateFn('timeUTC', service?.paid_date)}</div>
                        </td>
                        <td className=' py-3 align-top md:table-cell  md:w-auto'>
                          <div className='go-text-16 text-right'>{amountCheckFn(service?.net_amount)}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex justify-end'>
                <ClientButton
                  variant='primary'
                  text='View Invoice'
                  size='small'
                  classes=''
                  onClick={() => navigate(`/invoice/${orderDetails?.invoice?.transaction_id}`)}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}
