import React from 'react';
import statusColor from '../../../utils/statusColors';
import StatusChip from '../../../components/StatusChip';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { amountCheckFn } from '../../../utils/commonUtil';

export default function SingleOrder({ order, tipModalOpen }) {
  const navigate = useNavigate();
  return (
    <div
      className='cursor-pointer py-2 sm:py-4'
      onClick={() => {
        navigate(`/order/${order?.id}`);
      }}
    >
      <div className='w-full'>
        <div className='flex items-center justify-between'>
          <StatusChip status={order?.status} />
          <Link to={`/order/${order?.id}`} className='go-label-14'>
            Order #{order?.id}
          </Link>
        </div>
        <div className='my-1.5 flex items-start justify-between'>
          <div className='mb-1 items-center sm:mb-3'>
            <Link to={`/order/${order?.id}`} className='go-textblacknormal-18 mr-1 '>
              {order?.items[0]?.name}
            </Link>
            {order?.items.length > 1 && (
              <span className='text-xs'>
                {order?.items?.length > 2
                  ? `(+${order?.items?.length - 1} more services)`
                  : `(+${order?.items?.length - 1} more service)`}
              </span>
            )}
          </div>
          {(order?.status == 'WAITING' || order?.status == 'PICKUP_COMPLETE' || order?.status == 'DELIVERED') &&
            (order?.final_tip ? (
              <div className='text-left sm:text-center'>
                <span className='go-text-16'>Tip: {amountCheckFn(order?.final_tip)}</span>
              </div>
            ) : dateFn('daysBetween', order?.dropoff_date) <= 30 ? (
              <div className='text-right sm:text-center'>
                <Button
                  variant='secondary'
                  text='Add Tip'
                  classes='w-20 sm:w-32'
                  onClick={(e) => {
                    tipModalOpen(e, true, order);
                  }}
                />
              </div>
            ) : (
              <div className='text-left sm:text-center'>
                <span className='go-text-16'>Tip: $0 </span>
              </div>
            ))}
        </div>
        <div className=' flex flex-col-reverse justify-between sm:flex-row'>
          <div>
            <div className='mb-2 flex flex-col text-xs font-medium sm:flex-row'>
              <span className='go-label-15 pr-2'>Pick up:</span>
              <span className='go-textblackmedium-15'>
                {dateFn(
                  'toLocal',
                  order?.pickup_date,
                  order?.pickup_start_time,
                  order?.pickup_end_time,
                  order?.customer_address?.time_zone
                )}
              </span>
            </div>
            <div className='mb-2 flex flex-col text-xs font-medium sm:flex-row'>
              <span className='go-label-15 pr-2'>Delivery:</span>
              <span className='go-textblackmedium-15'>
                {dateFn(
                  'toLocal',
                  order?.dropoff_date,
                  order?.dropoff_start_time,
                  order?.dropoff_end_time,
                  order?.customer_address?.time_zone
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
