import React, { Fragment } from 'react';
import homeActive from '../../src/icons/menu/home-active.svg';
import homeInactive from '../../src/icons/menu/home-inactive.svg';
import orderActive from '../../src/icons/menu/orders-active.svg';
import orderInactive from '../../src/icons/menu/orders-inactive.svg';
import accountActive from '../../src/icons/menu/account-active.svg';
import accountInactive from '../../src/icons/menu/account-inactive.svg';
import menuActive from '../../src/icons/menu/menu-active.svg';
import menuInactive from '../../src/icons/menu/menu-inactive.svg';
import promoActive from '../../src/icons/menu/promo-active.svg';
import promoInactive from '../../src/icons/menu/promo-inactive.svg';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreateOrderSVG from '../components/Icons/CreateOrder';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Button from '../components/Button';

export default function Footer() {
  const { profileData } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const footerItems = [
    { name: 'Account', href: '/account', active: accountActive, inactive: accountInactive },
    { name: 'My Orders', href: '/my-orders', active: orderActive, inactive: orderInactive },
    { name: 'Home', href: '/', active: homeActive, inactive: homeInactive },
    { name: 'Promotions', href: '/promotions', active: promoActive, inactive: promoInactive },
  ];

  const menuItems = [
    {
      name: 'Services and Pricing',
      href: '/services-and-pricing',
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#001C5C" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <path d="M7.371 10.202v6.86M12.038 6.919v10.143M16.629 13.827v3.235M16.686 2H7.314C4.048 2 2 4.312 2 7.585v8.83C2 19.688 4.038 22 7.314 22h9.372C19.962 22 22 19.688 22 16.415v-8.83C22 4.312 19.962 2 16.686 2z"/>
    </g>
</svg>
`,
    },
    {
      name: 'Help',
      href: '/help',
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.378 2.11 1.905 6.748 2 12.37V17a4 4 0 1 0 4-4 3.91 3.91 0 0 0-2 .56v-1.19A8.2 8.2 0 0 1 12 4a8.2 8.2 0 0 1 8 8.37v1.19a3.91 3.91 0 0 0-2-.56 4 4 0 1 0 4 4v-4.63C22.095 6.748 17.622 2.11 12 2zM6 15a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm12 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" fill="#001C5C" fill-rule="nonzero"/>
</svg>
`,
    },
    {
      name: 'Privacy',
      href: '/privacy',
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#001C5C" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <path d="M16.584 3H7.915C4.894 3 3 5.139 3 8.166v8.168C3 19.361 4.885 21.5 7.915 21.5h8.668c3.031 0 4.917-2.139 4.917-5.166V8.166C21.5 5.139 19.614 3 16.584 3z" stroke-width="2"/>
        <path d="M10.939 12.25a1.852 1.852 0 1 1-1.852-1.852h.003a1.852 1.852 0 0 1 1.849 1.852zM10.942 12.25h6.318v1.852" stroke-width="2"/>
        <path stroke-width="1.5" d="M14.432 14.102V12.25"/>
    </g>
</svg>
`,
    },
    {
      name: 'Terms and Services',
      href: '/tos',
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g transform="rotate(90 12 12)" stroke="#001C5C" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <path d="M7.371 10.202v6.86M12.038 6.919v10.143M16.629 13.827v3.235M16.686 2H7.314C4.048 2 2 4.312 2 7.585v8.83C2 19.688 4.038 22 7.314 22h9.372C19.962 22 22 19.688 22 16.415v-8.83C22 4.312 19.962 2 16.686 2z"/>
    </g>
</svg>


`,
    },
  ];
  return (
    <footer className=''>
      {pathname !== '/create-new-order' && (
        <div className='fixed bottom-10 left-0 z-40  w-full px-2 pb-8 lg:hidden'>
          <button
            className='bg-client-primarylight flex w-full items-center justify-center rounded p-3 text-base font-medium text-white shadow-lg hover:opacity-90 focus-visible:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed  '
            onClick={() => navigate('/create-new-order')}
            disabled={profileData?.status !== 'ACTIVE' || !profileData?.is_allowed_to_create_order}
          >
            <CreateOrderSVG />
            Create Order
          </button>
        </div>
      )}

      <div className='fixed bottom-0 left-0 z-50 w-full bg-black pb-2 pt-2 lg:hidden'>
        <div className='relative mx-auto grid h-full max-w-lg grid-cols-5 pb-2 font-medium'>
          {footerItems.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className='group inline-flex flex-col items-center justify-center px-1 '
            >
              <div className='flex h-10 w-14 items-center justify-center bg-black align-middle'>
                <img src={pathname === item.href ? item.active : item.inactive} alt={item.name} className='h-5 w-5' />
              </div>
            </Link>
          ))}
          <Popover>
            {({ open, close }) => (
              <>
                <Popover.Button className='group inline-flex flex-col items-center justify-center px-1'>
                  <div className='flex h-10 w-14 items-center justify-center bg-black align-middle'>
                    <img src={open ? menuActive : menuInactive} alt='More' className='h-5 w-5' />
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-200'
                  enterFrom='opacity-0 -translate-y-1'
                  enterTo='opacity-100 translate-y-0'
                  leave='transition ease-in duration-150'
                  leaveFrom='opacity-100 translate-y-0'
                  leaveTo='opacity-0 -translate-y-1'
                >
                  <Popover.Panel
                    className='bg-client-bodybg absolute inset-x-0 bottom-0 z-50 mb-[56px] h-screen pt-14 shadow-lg ring-1 ring-gray-900/5'
                    onClick={close}
                  >
                    <div className='p-8'>
                      <p className='mb-10 text-center text-xl font-semibold text-gray-900'>Menu</p>
                      {menuItems.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          onClick={() => close()}
                          className='mb-5 flex flex-row items-center justify-start rounded-lg bg-white p-4 text-black'
                        >
                          <div className='flex items-center'>
                            <div className='bg-client-bodybg rounded-lg p-2'>
                              <div dangerouslySetInnerHTML={{ __html: item?.icon }}></div>
                            </div>
                            <span className='lg:sidebar-expanded:opacity-100  ml-3 duration-200 lg:opacity-0 2xl:opacity-100'>
                              {item?.name}
                            </span>
                          </div>
                        </NavLink>
                      ))}
                      <NavLink to={'/signin'} className='mb-5 flex'>
                        <Button
                          variant='secondary'
                          size='base'
                          text='Log Out'
                          classes='w-full py-4'
                          onClick={() => {
                            localStorage.removeItem('auth');
                            navigate('/signin');
                          }}
                        />
                      </NavLink>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
    </footer>
  );
}
