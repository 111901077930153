import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationsApi } from '../../../redux/Reducers/commonApis';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';

export default function IndividualNotifications({ accountType }) {
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const [intitalRender, setIntitalRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [notiData, setNotiData] = useState({
    email: [
      {
        name: 'New Orders',
        data: [
          {
            id: !accountType === 'ORGANIZATION_MEMBER' ? 'new_order_email' : 'new_order_email',
            type: 'New Orders',
            active: profileData?.new_order_email_notification || false,
          },
        ],
      },
      {
        name: 'Pickup Reminder',
        data: [
          {
            id: !accountType === 'ORGANIZATION_MEMBER' ? 'pickup_reminder_email' : 'pickup_reminder_email',
            type: 'Pickup Reminder',
            active: profileData?.pickup_reminder_email_notification || false,
          },
        ],
      },
      {
        name: 'Delivered Orders',
        data: [
          {
            id: !accountType === 'ORGANIZATION_MEMBER' ? 'delivered_orders_email' : 'delivered_orders_email',
            type: 'Delivered Orders',
            active: profileData?.delivered_orders_email_notification || false,
          },
        ],
      },
    ],
  });
  const onEmailSwitchChange = (name, id, status) => {
    setIntitalRender(false);
    setNotiData((prev) => {
      return {
        ...prev,
        email: prev?.email?.map((item) => {
          if (item?.name === name) {
            return {
              ...item,
              data: item?.data?.map((elm) => {
                if (elm?.id === id) {
                  return {
                    ...elm,
                    active: !status,
                  };
                }
                return elm;
              }),
            };
          }
          return item;
        }),
      };
    });
  };
  useEffect(() => {
    !intitalRender && updateNotificationsFn();
  }, [notiData]);

  const updateNotificationsFn = () => {
    setLoading(true);
    const payload = {};
    notiData?.email?.map((item) => {
      item?.data?.map((elm) => {
        payload[elm?.id] = elm?.active;
      });
    });

    const create = updateNotificationsApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setLoading(false);
        toast.success('Successfully updated');
        dispatch(getProfileDataApi());
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader text='processing...' classes='h-60' />
      ) : (
        <div className=''>
          <div className='z-0 rounded-lg bg-white pb-5'>
            <header className='flex items-center border-b border-gray-100 p-6'>
              <h5 className='go-textnormal-18 my-1 flex-1'>Order Updates via Email</h5>
            </header>
            {notiData?.email?.map((item, index) => (
              <div className='py-1 px-6' key={index}>
                {item?.data?.map((elm, index) => (
                  <div className='flex items-center justify-between' key={index}>
                    <div className='go-textblack-15 my-1.5'>{elm?.type}</div>
                    <div className='form-switch'>
                      <input
                        type='checkbox'
                        id={elm?.id}
                        className='sr-only'
                        checked={elm?.active}
                        onChange={(e) => onEmailSwitchChange(item?.name, elm?.id, elm?.active)}
                      />
                      <label className='bg-slate-400' htmlFor={elm?.id}>
                        <span className='bg-white shadow-sm' aria-hidden='true'></span>
                        <span className='sr-only'>Switch label</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
