import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';
import ModalBasic from '../../../components/Modal';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';
import { phone_add_plus_one, phone_remove_plus_one } from '../../../utils/commonUtil';
import { updateBillingDetailsApi } from '../../../redux/Reducers/commonApis';
import Button from '../../../components/Button';
const schema = yup.object({
  billing_contact_first_name: yup.string().required('Enter a valid first name'),
  billing_contact_last_name: yup.string().required('Enter a valid last name'),
  billing_contact_email: yup.string().email().required('Enter a valid email'),
  billing_contact_phone_number: yup.string().phone('US'),
});
export default function EditBillingContactModal({ modalOpen, setModalOpen, billingDetails, setEditBilling }) {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { isDirty, isTouched, errors, dirtyFields },
  } = useForm({ resolver: yupResolver(schema) });
  const [editLoading, setEditLoading] = useState(false);
  const onSubmit = (newData, e) => {
    setEditLoading(true);
    const temp = {};
    if (newData?.billing_contact_first_name === billingDetails?.billing_contact_first_name) {
      delete temp?.billing_contact_first_name;
    } else {
      temp.billing_contact_first_name = newData?.billing_contact_first_name;
    }
    if (newData?.billing_contact_last_name === billingDetails?.billing_contact_last_name) {
      delete temp?.billing_contact_last_name;
    } else {
      temp.billing_contact_last_name = newData?.billing_contact_last_name;
    }
    if (newData?.billing_contact_email === billingDetails?.billing_contact_email) {
      delete temp?.billing_contact_email;
    } else {
      temp.billing_contact_email = newData?.billing_contact_email;
    }
    if (newData?.billing_contact_phone_number === billingDetails?.billing_contact_phone_number[0]?.phone_number) {
      delete temp?.billing_contact_phone_number;
    } else {
      temp.billing_contact_phone_number =
        phone_add_plus_one(newData?.billing_contact_phone_number) ||
        billingDetails?.billing_contact_phone_number[0]?.phone_number;
    }

    const payload = { ...temp };
    const create = updateBillingDetailsApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setEditLoading(false);
        setModalOpen(false);
        toast.success('Billing details updated successfully');
      } else {
        setEditLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  return (
    <ModalBasic
      id='billing-edit-modal'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={'Edit Billing Contact'}
      width='md'
    >
      <div className=''>
        <div className='z-0 rounded-lg bg-white '>
          {/* <header className='flex items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Billing Contact</h5>
          </header> */}
          {editLoading ? (
            <Loader text='processing...' classes=' h-96' />
          ) : (
            <div className='p-5'>
              <div className='flex space-x-2'>
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <label className='go-label-16 mb-1 block' htmlFor='label'>
                    First Name
                  </label>
                  <input
                    name='billing_contact_first_name'
                    className={`go-inputtext-16`}
                    {...register('billing_contact_first_name', { required: true })}
                    type='text'
                    defaultValue={billingDetails?.billing_contact_first_name}
                  />
                  {errors.billing_contact_first_name?.message && (
                    <p className='go-textnormal-14 text-red-500'>{errors.billing_contact_first_name?.message}</p>
                  )}
                </div>
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <label className='go-label-16 mb-1 block' htmlFor='label'>
                    Last Name
                  </label>
                  <input
                    name='billing_contact_last_name'
                    className={`go-inputtext-16`}
                    {...register('billing_contact_last_name', { required: true })}
                    type='text'
                    defaultValue={billingDetails?.billing_contact_last_name}
                  />
                  {errors.billing_contact_last_name?.message && (
                    <p className='go-textnormal-14 text-red-500'>{errors.billing_contact_last_name?.message}</p>
                  )}
                </div>
              </div>

              <div className='relative mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Email
                </label>
                <input
                  {...register('billing_contact_email', { required: true })}
                  id='billing_contact_email'
                  className={`go-inputtext-16 ${
                    errors?.billing_contact_email?.type === 'required' ? 'border-red-500' : ''
                  }`}
                  type='email'
                  defaultValue={billingDetails?.billing_contact_email}
                />
                {errors.billing_contact_email?.message && (
                  <p className='go-textnormal-14 text-red-500'>{errors.billing_contact_email?.message}</p>
                )}
              </div>
              {/* <div className='relative mb-1 flex-row space-y-1.5'>
                     <select
                       {...register('phone_type', { required: true })}
                       className='go-label-16 block w-full border-transparent p-0 focus:border-transparent focus:ring-0 '
                       defaultValue={billingDetails?.billing_contact_phone_number?.type}
                     >
                       {phoneTypes?.map((option) => {
                         return (
                           <option key={option?.id} value={option?.value}>
                             {option?.displayValue}
                           </option>
                         );
                       })}
                     </select>
                   </div> */}
              <div className='relative mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Mobile
                </label>
                <input
                  {...register('billing_contact_phone_number', {
                    required: true,
                    message: 'Enter a valid phone number',
                  })}
                  id='billing_contact_phone_number'
                  className={`go-inputtext-16 ${
                    errors?.billing_contact_phone_number?.type === 'required' ? 'border-red-500' : ''
                  }`}
                  type='tel'
                  defaultValue={phone_remove_plus_one(billingDetails?.billing_contact_phone_number?.[0]?.phone_number)}
                />
                {errors.billing_contact_phone_number?.message && (
                  <p className='go-textnormal-14 text-red-500'>{errors.billing_contact_phone_number?.message}</p>
                )}
              </div>
            </div>
          )}
          <div className='flex space-x-4 p-5'>
            <Button variant='secondary' text='Cancel' classes='w-full' onClick={() => setModalOpen(false)} />
            <Button
              variant='primary-dark'
              text='Save'
              classes='w-full'
              onClick={(e) => {
                handleSubmit(onSubmit)(e);
              }}
            />
          </div>
        </div>
      </div>
    </ModalBasic>
  );
}
