import { Fragment, useCallback, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import AddIcon from './Icons/AddIcon';
import { setSelectedServiceAddressAction } from '../redux/Actions/ServiceAddressesActions';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export default function DropdownSingleAddress({ dropdownValues, icon, newAddress, newItem = false }) {
  const dispatch = useDispatch();
  const { selectedAddress } = useSelector(({ serviceAddresses }) => serviceAddresses);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(
    isEmpty(dropdownValues)
      ? {
          id: 0,
          value: 'No Address Found',
        }
      : dropdownValues[0]
  );

  const onAddressChange = useCallback(
    (val) => {
      if (val) {
        setSelected(val);
        dispatch(setSelectedServiceAddressAction(val));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setOptions(dropdownValues);
    setSelected(dropdownValues?.find((item) => item?.id === selectedAddress?.id));
  }, [dropdownValues, selectedAddress]);

  return (
    <div className=''>
      <Listbox value={selected || dropdownValues[0]} onChange={onAddressChange}>
        <div className=' relative mt-1'>
          <Listbox.Button className='go-textblacknormal-16 relative w-full cursor-default rounded border border-gray-200 bg-white py-2 pl-3 pr-12 text-left'>
            <span className='block truncate'>{selected?.value}</span>
            <span className='bg-client-iconbg pointer-events-none absolute inset-y-0 right-0 m-0.5 flex items-center rounded p-2'>
              <span className='text-client-link h-5 w-5' aria-hidden='true'>
                {icon ? icon : <ChevronUpDownIcon />}
              </span>
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='z-60 text-black-16 go-text-14 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {options?.map((item, itemIdx) => (
                <Listbox.Option
                  key={item?.id}
                  className={({ active }) =>
                    ` relative cursor-default select-none border-b border-gray-200 py-2 pl-10 pr-4 last:border-0 ${
                      active ? 'bg-blue-50 text-blue-600' : 'text-gray-900'
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{item.value}</span>
                      {selected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
              {newItem && (
                <Listbox.Option>
                  <div className='flex cursor-pointer justify-between px-10 py-2' onClick={() => newAddress(true)}>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      Add a new service address
                    </span>
                    <AddIcon />
                  </div>
                </Listbox.Option>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
