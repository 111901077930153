import React, { useEffect, useState } from 'react';
import AccordionBasic from '../../components/AccordionBasic';
import { getSupportApi } from '../../redux/Reducers/commonApis';
import Loader from '../../components/Loader';

const callsvg = (
  <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <g fill='#001c5c' fill-rule='nonzero'>
      <path d='M10.833 6.667a2.5 2.5 0 0 1 2.5 2.5.833.833 0 0 0 1.667 0A4.167 4.167 0 0 0 10.833 5a.833.833 0 0 0 0 1.667z' />
      <path d='M10.833 3.333a5.833 5.833 0 0 1 5.834 5.834.833.833 0 1 0 1.666 0 7.5 7.5 0 0 0-7.5-7.5.833.833 0 1 0 0 1.666zM18.125 13.258a.833.833 0 0 0-.6-.541l-5-1.142a.833.833 0 0 0-.767.217c-.116.108-.125.116-.666 1.15a8.258 8.258 0 0 1-4.059-4.075c1.059-.534 1.067-.534 1.175-.659a.833.833 0 0 0 .217-.766L7.283 2.5a.833.833 0 0 0-.541-.6 3.158 3.158 0 0 0-.6-.15 3.283 3.283 0 0 0-.642-.083A3.833 3.833 0 0 0 1.667 5.5c.009 7.084 5.75 12.824 12.833 12.833a3.833 3.833 0 0 0 3.833-3.833 3.617 3.617 0 0 0-.208-1.242zM14.5 16.667C8.335 16.662 3.338 11.665 3.333 5.5A2.175 2.175 0 0 1 5.5 3.333h.275L6.667 7.2l-.45.233c-.717.375-1.284.675-.984 1.325a9.875 9.875 0 0 0 5.984 6.009c.7.283.975-.242 1.35-.967l.241-.458 3.859.883v.275a2.175 2.175 0 0 1-2.167 2.167z' />
    </g>
  </svg>
);
const emailsvg = (
  <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.833 3.333H4.167a2.5 2.5 0 0 0-2.5 2.5v8.334a2.5 2.5 0 0 0 2.5 2.5h11.666a2.5 2.5 0 0 0 2.5-2.5V5.833a2.5 2.5 0 0 0-2.5-2.5zM15.275 5 10 8.958 4.725 5h10.55zm.558 10H4.167a.833.833 0 0 1-.834-.833V6.042L9.5 10.667a.833.833 0 0 0 1 0l6.167-4.625v8.125c0 .46-.373.833-.834.833z'
      fill='#001c5c'
      fill-rule='nonzero'
    />
  </svg>
);
const helpSvg = (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 2C6.378 2.11 1.905 6.748 2 12.37V17a4 4 0 1 0 4-4 3.91 3.91 0 0 0-2 .56v-1.19A8.2 8.2 0 0 1 12 4a8.2 8.2 0 0 1 8 8.37v1.19a3.91 3.91 0 0 0-2-.56 4 4 0 1 0 4 4v-4.63C22.095 6.748 17.622 2.11 12 2zM6 15a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm12 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4z'
      fill='#001c5c'
      fill-rule='nonzero'
    />
  </svg>
);
export default function Help() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getPrivacyFn = (id) => {
    setLoading(true);
    const response = getSupportApi();
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    getPrivacyFn();
  }, []);
  return (
    <div className='sm:max-w-9xl w-full p-4 sm:mx-auto sm:p-6'>
      {loading ? (
        <Loader text='loading ...' classes='h-96' />
      ) : (
        <>
          <div className='grid grid-cols-12'>
            <div className='col-span-full mb-5 rounded-lg bg-white md:col-span-6'>
              <h1 className='go-textblackbold-24 flex p-5 text-center sm:py-8'>
                <span className='mr-2 mt-0.5'>{helpSvg}</span> <span>Contact Support</span>
              </h1>
              <div className='flex-col space-y-2 border-t border-b px-5 py-4'>
                {data?.phone_number && (
                  <div className='bodyText flex'>
                    <a href={`tel:${data?.phone_number}`} className='flex'>
                      <span className='mr-3'>{callsvg}</span>
                      {data?.phone_number}
                    </a>
                  </div>
                )}
                {data?.email && (
                  <div className='bodyText flex'>
                    <a href={`mailto:${data?.email}`} className='flex'>
                      <span className='mr-3'>{emailsvg}</span>
                      {data?.email}
                    </a>
                  </div>
                )}
              </div>
              <div className='p-5'>
                <p className='bodyText mb-2'>Support Hours (EST)</p>
                {data?.support_schedules?.map((item, index) => (
                  <div className='mb-2 flex' key={index}>
                    <div className='bodyText w-20 opacity-50'>{item?.weekdays}</div>
                    <div className='bodyText font-medium'>{item?.hours}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <h1 className='go-textblacknormal-20 pl-5'>FAQ</h1>
          {data?.categories?.map((item, index) => (
            <div className='mt-2 p-5' key={index}>
              <p className=' mb-2 text-lg font-semibold'>{item?.name}</p>
              <div className='flex flex-1 flex-wrap  '>
                {item?.questions?.map((itm, idx) => (
                  <div className='w-full sm:w-1/2' key={idx}>
                    <div className='mb-2 mr-2 rounded-lg bg-white'>
                      <AccordionBasic title={itm?.question} status={idx <= 1 ? true : false}>
                        {itm?.answer}
                      </AccordionBasic>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
