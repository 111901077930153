import React, { useCallback, useEffect, useState } from 'react';
import ModalBasic from '../../../components/Modal';
import NewServiceAddressModal from './NewServiceAddressModal';
import SingleAddress from '../../MyAccount/ServiceAddresses/SingleAddress';
import { useDispatch, useSelector } from 'react-redux';
import { getAllServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';
import ModalToUpdateAddress from './ModalToUpdateAddress';
import ModalForOrders from './ModalForOrders';
import Loader from '../../../components/Loader';

export default function ServiceAddressModal({
  newAddressModalOpen,
  setNewAddressModalOpen,
  addressModalType,
  selectedAddressId,
}) {
  const { allServiceAddresses, allServiceAddressesLoading } = useSelector((state) => state.serviceAddresses);
  const dispatch = useDispatch();
  const [newAddressID, setNewAddressID] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [existingOrders, setExistingOrders] = useState(null);
  const [newAddressForOrders, setNewAddressForOrders] = useState(null);
  const [oldAddressForOrders, setOldAddressForOrders] = useState(null);

  const showUpdateModalFn = useCallback((e, val, res, type) => {
    e.stopPropagation();
    setShowUpdateModal(val);
    setNewAddressID({ id: res, type: type });
    // if(val === false) cancelNewAddress(res);
  }, []);
  const showOrderModalFn = useCallback((e, val, res, type, oldAddress, newAddress) => {
    e.stopPropagation();
    if (type === 'update') {
      setShowUpdateModal(val);
      setNewAddressID({ id: res, type: 'update' });
    } else {
      setShowOrderModal(val);
      setExistingOrders(res?.data);
      setNewAddressForOrders(newAddress);
      setOldAddressForOrders(oldAddress);
    }
  }, []);
  const onUpdateModalCancelFn = useCallback((val) => {
    setShowUpdateModal(false);
    setNewAddressModalOpen(false);
    //    cancelNewAddress(val);
  }, []);
  const onOrderModalCancelFn = useCallback((e, val, id, type) => {
    if (type === 'update') {
      setShowOrderModal(false);
      showUpdateModalFn(e, val, id, type);
    } else {
      setShowOrderModal(false);
      showUpdateModalFn(e, false, id);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllServiceAddressesApi());
  }, [!showUpdateModal]);

  return (
    <div>
      <ModalBasic
        id='new-address-modal'
        modalOpen={newAddressModalOpen}
        setModalOpen={setNewAddressModalOpen}
        title={addressModalType === 'new' ? 'Add New Address' : 'Edit Address'}
      >
        <div>
          {addressModalType === 'new' && (
            <NewServiceAddressModal
              modalOpen={newAddressModalOpen}
              setModalOpen={setNewAddressModalOpen}
              cancel={true}
            />
          )}
          {addressModalType === 'edit' && !showOrderModal && !showUpdateModal && (
            <SingleAddress
              address={allServiceAddresses?.find((address) => address?.id === selectedAddressId)}
              showModal={showOrderModalFn}
              setModalOpen={setNewAddressModalOpen}
              type='update'
            />
          )}
        </div>
        {loading ? (
          <Loader classes='h-40' text='loading..' bg='true' />
        ) : (
          <div className='py-1 sm:py-4'>
            {showUpdateModal && (
              <ModalToUpdateAddress
                modal={showUpdateModal}
                updateModal={setShowUpdateModal}
                newAddressID={newAddressID}
                addressess={allServiceAddresses}
                cancelModal={onUpdateModalCancelFn}
              />
            )}
            {showOrderModal && (
              <ModalForOrders
                modal={showOrderModal}
                setShowOrderModal={setShowOrderModal}
                updateModal={onOrderModalCancelFn}
                existingOrders={existingOrders}
                newAddress={newAddressForOrders}
                oldAddress={oldAddressForOrders}
                setLoading={setLoading}
              />
            )}
          </div>
        )}
      </ModalBasic>
    </div>
  );
}
