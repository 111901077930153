import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';

export default function ToolTip({ icon, children, classes }) {
  return (
    <div className=''>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button className={'relative'}>{icon}</Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className={' absolute z-10 -translate-x-1/2 transform  ' + classes}>
                {children}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
