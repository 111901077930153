import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import ClientButton from '../../../components/ClientButton';
import { useForm, useController, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';
import AddIcon from '../../../components/Icons/AddIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import ModalWithHeading from '../../../components/ModalWithHeading';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import Loader from '../../../components/Loader';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { phoneTypes } from '../../../mockdata/common';
import { updateProfileApi } from '../../../redux/Reducers/MyAccountReducer';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { phone_add_plus_one } from '../../../utils/commonUtil';
import { getAllServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';

const schema = yup.object({
  first_name: yup.string().required('Enter a valid name'),
  last_name: yup.string().required('Enter a valid name'),
  email: yup.string().email().required('Enter a valid email'),
  phone_number: yup.string().phone('US'),
});
const Checkboxes = ({ options, control, name }) => {
  const { field } = useController({
    control,
    name,
  });
  const [value, setValue] = React.useState(field.value || []);

  return (
    <>
      {options?.map((option, index) => (
        <label className='flex' key={index}>
          <input
            onChange={(e) => {
              const valueCopy = [...value];
              if (e.target.checked) {
                valueCopy.push({ service_address_id: e.target.value, operation: 'CREATE' });
              } else {
                const index = valueCopy.findIndex((item) => item?.id === e.target.value);
                valueCopy.splice(index, 1);
              }

              field.onChange(valueCopy);
              setValue(valueCopy);
            }}
            key={option?.id}
            defaultChecked={value?.includes(option?.id)}
            type='checkbox'
            value={option?.id}
            className='form-checkbox mt-1.5'
          />
          <span className='go-textblacknormal-16 ml-2 pt-0.5'>{option?.full_address}</span>
        </label>
      ))}
    </>
  );
};
export default function AddMembers({ setAdd, data }) {
  const dispatch = useDispatch();
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [newlyAddedMember, setNewlyAddedMember] = useState(null);
  const addMemberModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setAddMemberModal(val);
  }, []);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { isDirty, isTouched, errors, dirtyFields },
  } = useForm({ resolver: yupResolver(schema) });
  const [editLoading, setEditLoading] = useState(false);
  const onSubmit = (newData, e) => {
    setEditLoading(true);
    const temp = { operation: 'CREATE' };
    if (newData?.first_name && newData?.last_name && newData?.email && newData?.phone_number) {
      temp.first_name = newData?.first_name;
      temp.last_name = newData?.last_name;
      temp.email = newData?.email;
      temp.phone_number = {
        phone_number: phone_add_plus_one(newData?.phone_number),
        type: newData?.phone_type,
      };
      temp.service_addresses = newData?.service_addresses;
    }
    const payload = { members: [temp] };
    const create = updateProfileApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setEditLoading(false);
        addMemberModalFn(e, true);
        toast.success('Member added successfully!');
        setNewlyAddedMember(payload?.members[0]);
      } else {
        setEditLoading(false);
        setError('email', {
          type: 'custom',
          message: 'The mail address entered belongs to an existing account. Please enter a different email address.',
        });
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };

  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Add New Member</h5>
        </header>
        <div>
          <div className=' border-b border-gray-100 p-6'>
            {editLoading ? (
              <Loader text='processing...' classes=' h-96' />
            ) : (
              <div>
                {/* <div className=' mb-4 flex-row space-y-1.5'>
                  <label className='go-label-16 mb-1 block' htmlFor='label'>
                    Name
                  </label>
                  <input
                    name='first_name'
                    className={`go-inputtext-16`}
                    {...register('first_name', { required: true })}
                    type='text'
                  />
                  {errors.first_name?.message && (
                    <p className='go-textnormal-14 text-red-500'>{errors.first_name?.message}</p>
                  )}
                </div> */}
                <div className=' flex space-x-2'>
                  <div className=' mb-4 flex-row space-y-1.5'>
                    <label className='go-label-16 mb-1 block' htmlFor='label'>
                      First Name
                    </label>
                    <input
                      name='first_name'
                      className={`go-inputtext-16`}
                      {...register('first_name', { required: true })}
                      type='text'
                    />
                    {errors.first_name?.message && (
                      <p className='go-textnormal-14 text-red-500'>{errors.first_name?.message}</p>
                    )}
                  </div>
                  <div className=' mb-4 flex-row space-y-1.5'>
                    <label className='go-label-16 mb-1 block' htmlFor='label'>
                      Last Name
                    </label>
                    <input
                      name='last_name'
                      className={`go-inputtext-16`}
                      {...register('last_name', { required: true })}
                      type='text'
                    />
                    {errors.last_name?.message && (
                      <p className='go-textnormal-14 text-red-500'>{errors.last_name?.message}</p>
                    )}
                  </div>
                </div>
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <label className='go-label-16 mb-1 block' htmlFor='label'>
                    Email
                  </label>
                  <input
                    {...register('email', { required: true })}
                    className={`go-inputtext-16 ${errors?.email?.type === 'required' ? 'border-red-500' : ''}`}
                    type='email'
                  />
                  {errors.email?.message && <p className='go-textnormal-14 text-red-500'>{errors.email?.message}</p>}
                </div>
                <div className='relative mb-1 flex-row space-y-1.5'>
                  <select
                    {...register('phone_type', { required: true })}
                    className='go-label-16 block w-full border-transparent p-0 focus:border-transparent focus:ring-0 '
                  >
                    {phoneTypes?.map((option) => {
                      return (
                        <option key={option?.id} value={option?.value}>
                          {option?.displayValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <input
                    {...register('phone_number', {
                      required: true,
                      message: 'Enter a valid phone number',
                    })}
                    className={`go-inputtext-16 ${errors?.phone_number?.type === 'required' ? 'border-red-500' : ''}`}
                    type='tel'
                  />
                  {errors.phone_number?.message && (
                    <p className='go-textnormal-14 text-red-500'>{errors.phone_number?.message}</p>
                  )}
                </div>
                <div className='min- relative mb-4 flex-row space-y-1.5'>
                  <label className='go-label-16  mb-1 block' htmlFor='label'>
                    Service Address
                  </label>
                  <Checkboxes options={data?.addresses} control={control} name='service_addresses' />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='grid gap-1 p-5'>
          <ClientButton
            variant='primary'
            text='Save'
            classes='mx-2'
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
          />
          <ClientButton
            variant='secondary'
            text='Cancel'
            classes='mr-2'
            onClick={() => {
              setAdd(false);
            }}
          />
        </div>
      </div>
      <ModalWithHeadingSmall
        modalOpen={addMemberModal}
        setModalOpen={setAddMemberModal}
        title={`${newlyAddedMember?.first_name} ${newlyAddedMember?.last_name} has been added to your Organization.`}
        classes='max-w-lg py-4'
        clickOutsiteToClose={false}
      >
        <div className='mx-8'>
          <div>
            <p className='go-label-16 py-4 text-center'>
              An email with a link to set their password has been sent to {`${newlyAddedMember?.email}`}
            </p>
          </div>
          <div className='flex justify-center py-3 '>
            <ClientButton
              variant='primary'
              text='Close'
              classes=''
              onClick={() => {
                dispatch(getProfileDataApi(false));
                setAddMemberModal(false);
                setAdd(false);
              }}
            />
          </div>
        </div>
      </ModalWithHeadingSmall>
    </div>
  );
}
