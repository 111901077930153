import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/Loader';
import ClientButton from '../../../components/ClientButton';
import { phoneTypes } from '../../../mockdata/common';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { updateProfileApi } from '../../../redux/Reducers/MyAccountReducer';
import { toast } from 'react-hot-toast';
import { phone_add_plus_one, phone_remove_plus_one } from '../../../utils/commonUtil';
import ModalBasic from '../../../components/Modal';
import Button from '../../../components/Button';
export default function EditMyUserModal({ modalOpen, setModalOpen, data }) {
  const dispatch = useDispatch();
  const [editLoading, setEditLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    orgAdminFirstName: '',
    orgAdminLastName: '',
    email: '',
    phoneNumber: '',
    phoneType: '',
    orgAdminPhoneNumber: '',
    orgAdminPhoneType: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const validate = () => {
    let errors = {};
    if (!formData.email || formData.email.trim() === '') {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    const phoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (data?.account_type === 'ORGANIZATION') {
      if (!formData.orgAdminFirstName.trim()) {
        errors.orgAdminFirstName = 'First name is required';
      }
      if (!formData.orgAdminLastName.trim()) {
        errors.orgAdminLastName = 'Last name is required';
      }
      if (!formData.orgAdminPhoneNumber || formData.orgAdminPhoneNumber.trim() === '') {
        errors.orgAdminPhoneNumber = 'Phone number is required';
      } else if (!phoneRegex.test(formData.orgAdminPhoneNumber)) {
        errors.orgAdminPhoneNumber = 'Phone number is invalid';
      }
    } else {
      delete errors.orgAdminFirstName;
      delete errors.orgAdminLastName;
      delete errors.orgAdminPhoneNumber;

      if (!formData.firstName.trim()) {
        errors.firstName = 'First name is required';
      }
      if (!formData.lastName.trim()) {
        errors.lastName = 'Last name is required';
      }
      if (!formData.phoneNumber || formData.phoneNumber.trim() === '') {
        errors.phoneNumber = 'Phone number is required';
      } else if (!phoneRegex.test(formData.phoneNumber)) {
        errors.phoneNumber = 'Phone number is invalid';
      }
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    if (!validate()) {
      return;
    }
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      org_admin_first_name: formData.orgAdminFirstName,
      org_admin_last_name: formData.orgAdminLastName,
      email: formData.email,
      phone_number: {
        phone_number: `${formData.phoneNumber}`,
        type: formData.phoneType,
      },
      org_admin_phone_number: {
        phone_number: `${formData.orgAdminPhoneNumber}`,
        type: formData.orgAdminPhoneType,
      },
    };
    if (payload.first_name === data?.first_name) {
      delete payload.first_name;
    }
    if (payload.last_name === data?.last_name) {
      delete payload.last_name;
    }

    if (payload.email === data?.email) {
      delete payload.email;
    }

    if (data?.account_type !== 'ORGANIZATION') {
      delete payload.org_admin_first_name;
      delete payload.org_admin_last_name;
      delete payload.org_admin_phone_number;

      if (payload.phone_number?.phone_number === data?.phone_numbers?.[0]?.phone_number) {
        delete payload.phone_number;
      } else {
        payload.phone_number = {
          phone_number: phone_add_plus_one(formData.phoneNumber) || formData.phoneNumber,
          type: formData.phoneType,
        };
      }
    } else {
      delete payload.first_name;
      delete payload.last_name;
      delete payload.phone_number;
      if (payload.org_admin_first_name === data?.org_admin_first_name) {
        delete payload.org_admin_first_name;
      }
      if (payload.org_admin_last_name === data?.org_admin_last_name) {
        delete payload.org_admin_last_name;
      }
      if (
        payload?.org_admin_phone_number?.phone_number === data?.org_admin_phone_number[0]?.phone_number &&
        payload?.org_admin_phone_number?.type === data?.org_admin_phone_number[0]?.type
      ) {
        delete payload.org_admin_phone_number;
      } else {
        payload.org_admin_phone_number = {
          phone_number: phone_add_plus_one(formData.orgAdminPhoneNumber) || formData.orgAdminPhoneNumber,
          type: formData.orgAdminPhoneType,
        };
      }
    }

    setEditLoading(true);

    const create = updateProfileApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setEditLoading(false);
        toast.success('Profile updated successfully');
        dispatch(getProfileDataApi(false));
      } else {
        setEditLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    if (data) {
      setFormData({
        firstName: data?.first_name || data?.org_admin_first_name,
        lastName: data?.last_name || data?.org_admin_last_name,
        orgAdminFirstName: data?.org_admin_first_name || '',
        orgAdminLastName: data?.org_admin_last_name || '',
        email: data?.email,
        phoneNumber: (data?.phone_numbers && data?.phone_numbers?.[0]?.phone_number) || '',
        phoneType: (data?.phone_numbers && data?.phone_numbers?.[0]?.type) || phoneTypes[0]?.value,
        orgAdminPhoneNumber: (data?.org_admin_phone_number && data?.org_admin_phone_number[0]?.phone_number) || '',
        orgAdminPhoneType:
          (data?.org_admin_phone_number && data?.org_admin_phone_number[0]?.type) || phoneTypes[0]?.value,
      });
    }
  }, [data]);

  return (
    <ModalBasic
      id='edit-my-user-modal'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={'Edit My User'}
      width='md'
    >
      <div className=''>
        <div className='z-0 rounded-lg bg-white '>
          {/* <header className='flex items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>My User</h5>
          </header> */}
          {editLoading ? (
            <Loader text='processing...' classes='h-40' />
          ) : (
            <div className='p-6'>
              <div className='flex space-x-2'>
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <label className='go-label-16 mb-1 block' htmlFor=''>
                    First Name
                  </label>
                  {data?.account_type !== 'ORGANIZATION' ? (
                    <input
                      type='text'
                      className={`go-inputtext-16`}
                      id='firstName'
                      name='firstName'
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  ) : (
                    <input
                      type='text'
                      className={`go-inputtext-16`}
                      id='orgAdminFirstName'
                      name='orgAdminFirstName'
                      value={formData.orgAdminFirstName}
                      onChange={handleChange}
                    />
                  )}
                  {data?.account_type !== 'ORGANIZATION'
                    ? formErrors.firstName && <p style={{ color: 'red' }}>{formErrors.firstName}</p>
                    : formErrors.orgAdminFirstName && <p style={{ color: 'red' }}>{formErrors.orgAdminFirstName}</p>}
                </div>
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <label className='go-label-16 mb-1 block' htmlFor='label'>
                    Last Name
                  </label>
                  {data?.account_type !== 'ORGANIZATION' ? (
                    <input
                      type='text'
                      className={`go-inputtext-16`}
                      id='lastName'
                      name='lastName'
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  ) : (
                    <input
                      type='text'
                      className={`go-inputtext-16`}
                      id='orgAdminLastName'
                      name='orgAdminLastName'
                      value={formData.orgAdminLastName}
                      onChange={handleChange}
                    />
                  )}
                  {data?.account_type !== 'ORGANIZATION'
                    ? formErrors.lastName && <p style={{ color: 'red' }}>{formErrors.lastName}</p>
                    : formErrors.orgAdminLastName && <p style={{ color: 'red' }}>{formErrors.orgAdminLastName}</p>}
                </div>
              </div>
              <div className='relative mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Email
                </label>
                <input
                  type='email'
                  className={`go-inputtext-16`}
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && <p style={{ color: 'red' }}>{formErrors.email}</p>}
              </div>
              {data?.account_type === 'ORGANIZATION' ? (
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <select
                    className='go-label-16 block w-full border-transparent p-0 focus:border-transparent focus:ring-0 '
                    id='orgAdminPhoneType'
                    name='orgAdminPhoneType'
                    value={formData.orgAdminPhoneType}
                    onChange={handleChange}
                  >
                    {phoneTypes?.map((option) => {
                      return (
                        <option key={option?.id} value={option?.value}>
                          {option?.displayValue}
                        </option>
                      );
                    })}
                  </select>
                  <label className='go-label-16 mb-1 hidden' htmlFor='label'>
                    Phone Number
                  </label>
                  <input
                    type='tel'
                    className={`go-inputtext-16`}
                    id='orgAdminPhoneNumber'
                    name='orgAdminPhoneNumber'
                    pattern='\d{10}'
                    value={formData.orgAdminPhoneNumber}
                    onChange={handleChange}
                  />
                  {formErrors.orgAdminPhoneNumber && <p style={{ color: 'red' }}>{formErrors.orgAdminPhoneNumber}</p>}
                </div>
              ) : (
                <div className='relative mb-4 flex-row space-y-1.5'>
                  <select
                    className='go-label-16 block w-full border-transparent p-0 focus:border-transparent focus:ring-0 '
                    id='phoneType'
                    name='phoneType'
                    value={formData.phoneType}
                    onChange={handleChange}
                  >
                    {phoneTypes?.map((option) => {
                      return (
                        <option key={option?.id} value={option?.value}>
                          {option?.displayValue}
                        </option>
                      );
                    })}
                  </select>
                  <label className='go-label-16 mb-1 hidden' htmlFor='label'>
                    Phone Number
                  </label>
                  <input
                    type='tel'
                    className={`go-inputtext-16`}
                    id='phoneNumber'
                    name='phoneNumber'
                    pattern='\d{10}'
                    defaultValue={phone_remove_plus_one(formData.phoneNumber)}
                    onChange={handleChange}
                  />
                  {formErrors.phoneNumber && <p style={{ color: 'red' }}>{formErrors.phoneNumber}</p>}
                </div>
              )}
              {/* <div className='mb-4 flex-row space-y-1.5'>
              <div className='go-label-16'>Account Type</div>
              <input
                id='label'
                className='go-inputtext-16 disabled:bg-indigo-50'
                type='text'
                defaultValue={accountTypeFormatFn(data?.account_type)}
                disabled
              />
            </div> */}
            </div>
          )}
          <div className='flex space-x-4 p-5'>
            <Button variant='secondary' text='Cancel' classes='w-full' onClick={() => setModalOpen(false)} />
            <Button
              variant='primary-dark'
              text='Save'
              classes='w-full'
              onClick={(e) => {
                handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </ModalBasic>
  );
}
