import React from 'react';

export default function CreateOrderSVG() {
  return (
    <div>
      <svg className='mr-2 h-6 w-6' viewBox='0 0 71.570717 58.079216' version='1.1' id='svg1'>
        <defs id='defs1' />
        <g id='layer1' transform='translate(80.881542,-61.490684)'>
          <g id='g2'>
            <g id='g1'>
              <g id='g4'>
                <path
                  style={{
                    fill: 'none',
                    fillOpacity: 1,
                    fillRule: 'evenodd',
                    stroke: '#ffffff',
                    strokeWidth: 4.55595,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeOpacity: 1,
                  }}
                  d='m -47.829634,70.693669 -19.463023,0.0091 c -6.985785,0.04247 -11.310905,4.640992 -11.310905,11.65109 v 23.274831 c 0,7.04653 4.358526,11.66323 11.40506,11.66323 l 19.463024,-0.009 c 6.985795,-0.0425 11.313946,-4.64099 11.313946,-11.65412 V 82.356904 c 0,-7.046534 -4.361556,-11.660193 -11.408102,-11.660193 z'
                  id='path4'
                />
                <path
                  style={{
                    fill: 'none',
                    fillOpacity: 1,
                    fillRule: 'evenodd',
                    stroke: '#ffffff',
                    strokeWidth: 4.55595,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeOpacity: 1,
                  }}
                  d='m -59.617399,83.62954 h -6.973639'
                  id='path3'
                />
                <path
                  style={{
                    fill: 'none',
                    fillOpacity: 1,
                    fillRule: 'evenodd',
                    stroke: '#ffffff',
                    strokeWidth: 4.55595,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeOpacity: 1,
                  }}
                  d='M -48.31864,94.199347 H -66.591038'
                  id='path2'
                />
                <path
                  style={{
                    fill: 'none',
                    fillOpacity: 1,
                    fillRule: 'evenodd',
                    stroke: '#ffffff',
                    strokeWidth: 4.55595,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeOpacity: 1,
                  }}
                  d='M -48.31864,104.79649 H -66.591038'
                  id='path1'
                />
                <path
                  d='m -11.588725,72.0792 h -8.310604 v -8.310608 c 0,-1.244239 -1.008636,-2.252878 -2.252874,-2.252878 -1.244236,0 -2.252876,1.008639 -2.252876,2.252878 V 72.0792 h -8.310602 c -1.244237,0 -2.252875,1.008637 -2.252875,2.252882 0,1.244229 1.008638,2.252863 2.252875,2.252863 h 8.310602 v 8.310609 c 0,1.24425 1.00864,2.25288 2.252876,2.25288 1.244238,0 2.252874,-1.00863 2.252874,-2.25288 v -8.310609 h 8.310604 c 1.244241,0 2.2528767,-1.008634 2.2528767,-2.252863 0,-1.244245 -1.0086357,-2.252882 -2.2528767,-2.252882 z'
                  id='path1-2'
                  style={{
                    fill: '#ffffff',
                    stroke: '#ffffff',
                    strokeWidth: 0.0500639,
                    strokeOpacity: 0.6,
                  }}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
