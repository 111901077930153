import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import './css/style.css';
import CreateNewOrder from './pages/CreateNewOrder';
import Dashboard from './pages/Dashboard';
import Help from './pages/Help';

import Landing from './pages/Landing';
import Messages from './pages/Messages';
import MyAccount from './pages/MyAccount';
import Privacy from './pages/Privacy';
import Promotions from './pages/Promotions';
import ServicesAndPricing from './pages/ServicesAndPricing';
import Signin from './pages/Signin';
import TermsAndConditions from './pages/TermsAndConditions';
import OrderList from './pages/Orders/OrderList';
import OrderView from './pages/Orders/OrderView';
import Invoice from './pages/Invoice';
import AuthGuard from './components/AuthGaurd';
import { Toaster } from 'react-hot-toast';
import IOSLanding from './pages/IOSLanding';
import AndroidLanding from './pages/AndroidLanding';

function App() {
  const tenantName = import.meta.env.VITE_TENANT_NAME;
  const env = import.meta.env.VITE_APP_ENV;
  if (tenantName == 'laundrycare') {
    localStorage.setItem('tenantName', 'Laundry Care');
  } else if (tenantName == 'pushlaundry') {
    localStorage.setItem('tenantName', 'Push Laundry');
  } else {
    localStorage.setItem('tenantName', 'Tenant');
  }
  if (env) {
    localStorage.setItem('appEnv', env);
  } else {
    localStorage.setItem('appEnv', '');
  }

  const location = useLocation();
  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]);

  const siteId = tenantName == 'laundrycare' ? 4999130 : 4999136;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  return (
    <>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/create-new-order' element={<CreateNewOrder />} />
          <Route path='/promotions' element={<Promotions />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/my-orders' element={<OrderList />} />
          <Route path='/order/:id' element={<OrderView />} />
          <Route path='/invoice/:id' element={<Invoice />} />
          <Route path='/account' element={<MyAccount />} />
          <Route path='/messages' element={<Messages />} />
          <Route path='/services-and-pricing' element={<ServicesAndPricing />} />
          <Route path='/help' element={<Help />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/tos' element={<TermsAndConditions />} />
        </Route>
        <Route path='/ios' element={<IOSLanding />} />
        <Route path='/android' element={<AndroidLanding />} />
        <Route path='/signin' element={<Signin />} />
        <Route path='*' element={<Signin />} />
      </Routes>
      <Toaster position='top-right' reverseOrder={false} />
    </>
  );
}

export default App;
