import React, { Suspense, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import Stripe from '../../../components/Stripe';
import ModalBasic from '../../../components/Modal';
import { useSelector } from 'react-redux';
export default function EditPaymentInfoModal({ modalOpen, setModalOpen }) {
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [payClick, setPayClick] = useState(false);
  const onPayClick = () => {
    setPayClick(!payClick);
  };
  const onCardSaveFn = () => {
    setModalOpen(false);
  };
  return (
    <ModalBasic
      id='edit-payment-info-modal'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={'Edit Payment Info'}
      width='md'
    >
      <div className='z-0 rounded-lg bg-white'>
        <div className='p-6'>
          <div className='flex'>
            <div className='w-full rounded-lg'>
              <div className=''>
                {modalOpen && (
                  <Stripe
                    addressid={selectedAddress?.id}
                    cardAdded={() => onCardSaveFn()}
                    cancel={() => setModalOpen(false)}
                  />
                )}
                {/* <div className='grid gap-1'>
                  <ClientButton variant='secondary' text='Cancel' classes='mr-2' onClick={() => setModalOpen(false)} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  );
}
