import React, { useEffect, useState } from 'react';
import Profile from './Profile';
import Payments from './Payments';
import Subscriptions from './Subscriptions';
import Notifications from './Notifications';
import ServiceAddresses from './ServiceAddresses';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  AdjustmentsHorizontalIcon,
  ArrowPathIcon,
  BellIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import CreditCard from '../../components/Icons/CreditCard';
import Settings from './Settings';
import { useLocation } from 'react-router-dom';

export default function MyAccount() {
  const { allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  const { newSubscriptionId } = useSelector((state) => state.createNewOrder);
  const { profileData } = useSelector((state) => state.profile);
  const [allLocations, setAllLocations] = useState([]);
  const location = useLocation();
  const [tabs, setTabs] = useState([
    { name: 'Profile', active: true },
    { name: 'Payment', active: false },
    { name: 'Subscriptions', active: false },
    { name: 'ServiceAddress', active: false },
    { name: 'Settings', active: false },
  ]);
  const setActiveTab = (name) => {
    const updatedTabs = tabs.map((tab) => {
      if (tab.name === name) {
        tab.active = true;
      } else {
        tab.active = false;
      }
      return tab;
    });
    setNewAddress(false);
    setTabs(updatedTabs);
  };
  const [newAddress, setNewAddress] = useState(false);
  const newAddressFn = (val) => {
    setActiveTab('ServiceAddress');
    setNewAddress(true);
  };
  useEffect(() => {}, [newAddress]);
  useEffect(() => {
    if (allServiceAddresses) {
      const locations = allServiceAddresses.map((address) => {
        return {
          id: address.id,
          value: `${address?.name}, ${address?.address_line_1}`,
        };
      });
      setAllLocations(locations);
    }
  }, [allServiceAddresses]);

  useEffect(() => {
    if (!isEmpty(profileData?.past_due_invoices)) {
      setActiveTab('Payment');
    }
  }, [profileData?.past_due_invoices]);
  useEffect(() => {
    if (newSubscriptionId) {
      setActiveTab('Subscriptions');
    }
  }, [newSubscriptionId]);

  useEffect(() => {
    if (location?.state?.from && location.pathname === '/account') {
      setActiveTab('Payment');
    }
  }, [location?.state?.from]);

  const renderTab = (tabname) => {
    switch (tabname) {
      case 'Profile':
        return <Profile setActiveTab={setActiveTab} />;
      case 'Payment':
        return <Payments setActiveTab={setActiveTab} allLocations={allLocations} />;
      case 'Subscriptions':
        return <Subscriptions setActiveTab={setActiveTab} allLocations={allLocations} />;
      case 'Notifications':
        return <Notifications />;
      case 'ServiceAddress':
        return (
          <ServiceAddresses
            newaddress={newAddress}
            cancelNewAddress={(val) => setNewAddress(val)}
            setTabs={setActiveTab}
          />
        );
      case 'Settings':
        return <Settings setActiveTab={setActiveTab} allLocations={allLocations} />;

      default:
        return <Profile />;
    }
  };

  const iconsSwitch = (tabname) => {
    switch (tabname) {
      case 'Profile':
        return <UserIcon className='inline-block h-5 w-5' strokeWidth={2} />;
      case 'Payment':
        return <CreditCardIcon className='inline-block h-5 w-5' strokeWidth={2} />;
      case 'Subscriptions':
        return <ArrowPathIcon className='inline-block h-5 w-5' strokeWidth={2} />;
      case 'Preferences':
        return <AdjustmentsHorizontalIcon className='inline-block h-5 w-5' strokeWidth={2} />;
      case 'Notifications':
        return <BellIcon className='inline-block h-5 w-5' strokeWidth={2} />;
      case 'Settings':
        return <Cog6ToothIcon className='inline-block h-5 w-5' strokeWidth={2} />;
      default:
        return null;
    }
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <>
      <div className='mb-4 flex flex-col justify-between bg-white sm:mb-6 sm:flex-row'>
        {/* <ul className=' mb-4 inline-flex h-10 rounded-full border border-blue-300 bg-white sm:h-12'>
          {tabs.map((tab, index) => {
            if (tab.name != 'ServiceAddress') {
              return (
                <li key={index} className='flex flex-row sm:flex-col'>
                  {iconsSwitch(tab.name)}
                  <button
                    onClick={() => setActiveTab(tab.name)}
                    className={`${
                      tab.active ? 'go-link-18 bg-[#EDF3FE]' : 'go-labelmedium-18'
                    }  mt-0.5 flex items-center rounded-full px-3 py-1.5 sm:px-8 sm:py-2`}
                  >
                    {tab.name}
                  </button>
                </li>
              );
            }
          })}
        </ul> */}
        <nav className='max-w-9xl mx-auto flex w-full space-x-1 bg-white p-2 px-4 sm:px-6' aria-label='Tabs'>
          {tabs.map((tab, index) => {
            if (tab.name != 'ServiceAddress') {
              return (
                <a
                  key={index}
                  href='#'
                  onClick={() => setActiveTab(tab.name)}
                  className={`${
                    tab.active ? 'text-client-primarylight  bg-blue-100 ' : ' text-gray-800'
                  }   go-textblack-15 w-full rounded-xl bg-white py-2 px-2 text-center sm:w-32  `}
                >
                  {iconsSwitch(tab.name)}
                  <div
                    className={`mt-1 flex justify-center ${
                      tab.active ? 'go-textblack-15 text-client-primarylight' : ' go-label-15'
                    }`}
                  >
                    {tab.name}
                  </div>
                </a>
              );
            }
          })}
        </nav>
        {/* {tabs.find((tab) => tab.active).name === 'ServiceAddress'
          ? !newAddress &&
            profileData?.is_allowed_to_add_address && (
              <ClientButton
                variant='primary'
                text='Add a Service Address'
                classes=''
                size='small'
                onClick={() => {
                  setNewAddress(true);
                }}
              />
            )
          : (tabs.find((tab) => tab.active).name === 'Orders' ||
              tabs.find((tab) => tab.active).name === 'Payments') && (
              <div className='w-full pt-2 sm:w-auto'>
                <span className='go-label-16'>Location</span>
                <div className='flex'>
                  <DropdownSingleAddress dropdownValues={allLocations} newAddress={(e) => newAddressFn(e)} />
                  <button className='go-btn-primary ml-2 mt-1'>
                    <EditIcon onClick={() => setActiveTab('ServiceAddress')} />
                  </button>
                </div>
              </div>
            )} */}
      </div>
      {/* <hr className='go-hr-dashed my-5'></hr> */}
      {/* <hr className='go-hr-dashed my-5'></hr> */}
      {renderTab(tabs.find((tab) => tab.active).name)}
    </>
  );
}
