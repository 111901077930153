import React, { useEffect, useState } from 'react';
import { getTOSApi } from '../redux/Reducers/commonApis';
import { dateFn } from '../utils/dateTimeFormatter';
import Loader from '../components/Loader';
export default function TermsAndConditions() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getPrivacyFn = (id) => {
    setLoading(true);
    const response = getTOSApi();
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    getPrivacyFn();
  }, []);

  return (
    <div className='sm:max-w-9xl w-full p-4 sm:mx-auto sm:p-6'>
      {loading ? (
        <Loader text='loading terms of conditions...' classes='h-96' />
      ) : (
        <div className=''>
          <p className='go-textblacknormal-16'>
            Last modified {dateFn('MM/DD/YYYY', data?.terms_of_service_modified_date)}
          </p>
          <div className='rounded-lg bg-white p-5'>
            <p className='mb-5'>{data?.terms_of_service}</p>
          </div>
        </div>
      )}
    </div>
  );
}
