import React, { useCallback, useEffect, useState } from 'react';
import DropdownSingle from '../../../components/DropdownSingle';
import ClientButton from '../../../components/ClientButton';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import Loader from '../../../components/Loader';
import SingleAddress from './SingleAddress';

export default function Addresses({ addresses, loadingStatus, showModal, setTabs }) {
  return (
    <>
      {loadingStatus ? (
        <Loader classes='h-80' text='fetching all service addressess' bg='true' />
      ) : (
        <div className=''>
          {addresses?.map((address, index) => (
            <SingleAddress address={address} key={index} showModal={showModal} />
          ))}
          <div className='grid gap-1 py-6 md:grid-cols-4'>
            <ClientButton
              variant='secondary'
              text='Cancel'
              classes='mr-2'
              onClick={() => {
                setTabs('Profile');
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
