import React from 'react';

export default function InfoCircle({ classes }) {
  return (
    <div className={classes}>
      <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
        <g fill='#001442' fill-rule='nonzero'>
          <path d='M8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333zm0 12A5.333 5.333 0 1 1 8 2.667a5.333 5.333 0 0 1 0 10.666z' />
          <circle cx='8' cy='5.333' r='1' />
          <path d='M8 6.667a.667.667 0 0 0-.667.666v3.334a.667.667 0 0 0 1.334 0V7.333A.667.667 0 0 0 8 6.667z' />
        </g>
      </svg>
    </div>
  );
}
