import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import ClientButton from '../../components/ClientButton';
import LeftArrow from '../../components/Icons/LeftArrow';
import { toast } from 'react-hot-toast';
import OrderConfirmation from './OrderConfirmation';
import OrderSummary from './OrderSummary';
import PaymentMethod from './PaymentMethod';
import PickupAddress from './PickupAddress';
import PickupAndDelivery from './PickupAndDelivery';
import SelectProvider from './SelectProvider';
import SelectServices from './SelectServices';
import OrderType from './OrderType';
import { setOrderDataAction, setOrderTypeAction } from '../../redux/Actions/CreateOrderActions';
import { ArrowLongLeftIcon, ArrowLongRightIcon, MapIcon, MapPinIcon } from '@heroicons/react/20/solid';
import RightArrow from '../../components/Icons/RightArrow';
import { resetCreateOrderFlow } from '../../redux/Reducers/CreateOrderReducer';

export default function CreateNewOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    orderType,
    orderServiceAddress,
    selectedServices,
    orderTimeSlots,
    orderRepeatFrequency,
    orderProvider,
    orderPaymentDetails,
  } = useSelector((state) => state.createNewOrder);
  const { orderPreferences } = useSelector((state) => state.myaccount);
  const { profileData } = useSelector((state) => state.profile);
  const [selected, setSelected] = useState(1);
  const [count, setCount] = useState(1800);
  const changeStepTitle = (val) => {
    switch (val) {
      case 1:
        return 'Create New Order';
      case 2:
        return 'Select Pickup Address';
      case 3:
        return 'Select Services';
      case 4:
        return 'Select Provider & Pickup';
      case 5:
        return 'Select Delivery';
      case 6:
        return 'Confirm Payment';
      case 7:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      case 8:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      default:
        return '';
    }
  };

  const orderCreationValidation = (val) => {
    switch (val) {
      case 1:
        return !orderType;
      case 2:
        return !orderServiceAddress;
      case 3:
        return orderServicesValidationFn();
      case 4:
        return !orderTimeSlots && !orderProvider;
      case 5:
        return orderPickupDeliveryValidationFn(orderTimeSlots);
      case 6:
        return orderPaymentDetailsValidationFn();
      case 7:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      case 8:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      default:
        return '';
    }
  };

  const orderServicesValidationFn = () => {
    if (isEmpty(selectedServices)) {
      return true;
    } else {
      return false;
    }
  };
  const orderPickupDeliveryValidationFn = (slots) => {
    if (orderType === 'oneTime') {
      return !(
        slots?.pickup_date &&
        slots?.pickup_start_time &&
        slots?.pickup_end_time &&
        slots?.dropoff_date &&
        slots?.dropoff_start_time &&
        slots?.dropoff_end_time
      );
    } else if (orderType === 'subscription') {
      return !(
        slots?.first_pickup_date &&
        slots?.pickup_start_time &&
        slots?.pickup_end_time &&
        slots?.dropoff_date &&
        slots?.dropoff_start_time &&
        slots?.dropoff_end_time &&
        orderRepeatFrequency
      );
    }
  };

  const orderPaymentDetailsValidationFn = () => {
    let type = orderPaymentDetails?.billing_details?.default_payment_method_type;
    if (type == 'ACH' || type == 'CHECK' || type == 'STRIPE') {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (profileData?.status !== 'ACTIVE') {
      toast.error('Your account is inactive!');
      navigate('/dashboard');
    } else if (!profileData?.is_allowed_to_create_order) {
      toast.error('Oops! You have a past due invoice. Make a payment to order.');
      navigate('/account', { state: { from: 'payments' } });
    }
  }, [profileData?.status]);

  const backButton = (
    <ClientButton variant='secondary' text='Back' classes='mr-2' onClick={() => setSelected(selected - 1)} />
  );

  const nextButton = (
    <ClientButton
      variant='primary'
      text='Next'
      classes='mx-2'
      disabled={orderCreationValidation(selected)}
      onClick={() => setSelected(selected + 1)}
    />
  );
  useEffect(() => {
    selected == 1 && dispatch(resetCreateOrderFlow());
    if (selected == 4) {
      setCount(1800);
    } else if (selected == 8) {
      setCount(1800);
    }
  }, [selected]);

  useEffect(() => {
    let timer;
    if (!isEmpty(orderTimeSlots)) {
      timer = setInterval(() => {
        setCount((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [orderTimeSlots]);
  useEffect(() => {
    if (count === 300) {
      toast.error(
        `You've been inactive for a while. Your session will expire soon. Please complete your order to avoid losing your progress.`
      );
    } else if (count === 0) {
      setSelected(1);
      toast.error(`Your session expired due to inactivity. Please start a new order.`);
      setCount(1800);
    } else if (selected == 1 || selected == 2 || selected == 3) {
      setCount(1800);
    }
  }, [count]);
  return (
    <div className=''>
      <div className=' bg-white '>
        <div className='sm:max-w-9xl px-4 py-4 sm:mx-auto sm:px-6 '>
          <div className='flex flex-col items-center justify-center space-y-3 py-2 sm:py-4 '>
            <h3 className='go-textblackbold-18 sm:go-textblackbold-24'>{changeStepTitle(selected)}</h3>
            {orderServiceAddress?.full_address && (
              <div className='text-client-primary go-textblackbold-14 flex items-center align-middle font-semibold'>
                <MapPinIcon className='h-6 w-6' />
                <span>{orderServiceAddress?.full_address}</span>
              </div>
            )}
          </div>
          <div className='flex items-center justify-between py-2 sm:mb-4 sm:py-0'>
            {selected > 1 && selected !== 8 ? (
              <span>
                <button
                  className={`btn bg-client-text cursor-pointer rounded-full text-white `}
                  onClick={() => setSelected(selected - 1)}
                >
                  <ArrowLongLeftIcon onClick={() => setSelected(selected - 1)} className={`h-6 w-6 sm:w-10`} />
                  <span className='ml-2 mr-0 sm:ml-0 sm:mr-2'>Back</span>
                </button>
              </span>
            ) : (
              <span className='w-24'></span>
            )}

            {selected > 0 && selected !== 7 && selected !== 8 ? (
              <span>
                <button
                  className={`btn bg-client-text cursor-pointer rounded-full text-white ${
                    orderCreationValidation(selected)
                      ? 'pointer-events-none opacity-50'
                      : 'pointer-events-auto opacity-100'
                  }`}
                  onClick={() => setSelected(selected + 1)}
                >
                  <span className='ml-0 mr-2 sm:ml-2 sm:mr-0'>Next</span>
                  <ArrowLongRightIcon className={`h-6 w-6 sm:w-10`} />
                </button>
              </span>
            ) : (
              <span className='w-24'></span>
            )}
          </div>
        </div>
      </div>
      <div className='sm:max-w-9xl w-full p-4 sm:mx-auto sm:p-6'>
        <div className='w-full'>
          {/* {selected <= 6 && (
            <div className='relative m-2'>
              <div className='bg-client-bgstep absolute  left-0 h-4 w-full rounded-full ' aria-hidden='true'></div>
              <ul className='text-semibold relative flex w-full justify-between text-sm'>
                {[1, 2, 3, 4, 5, 6].map((step, i) => (
                  <li className={`  ${step === 1 || step === 6 ? ' w-3/12' : ' w-5/12'}`} key={i}>
                    <div
                      className={`relative flex h-6 ${
                        step === 1 ? ' justify-start' : step === 6 ? ' justify-end' : ' justify-center'
                      } `}
                    >
                      <span
                        className={
                          'mb-2 flex h-4 w-4 items-center justify-center rounded-full text-xs font-semibold  text-slate-500 ' +
                          (selected === step
                            ? 'bg-client-currentstep'
                            : selected < step
                            ? 'bg-client-incompletestep'
                            : 'bg-client-completedstep')
                        }
                      ></span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )} */}
          {selected === 1 && (
            <div className='relative'>
              <OrderType />
            </div>
          )}

          {selected === 2 && (
            <div className='relative'>
              <PickupAddress />
            </div>
          )}
          {selected === 3 && (
            <div className='relative'>
              <SelectServices />
            </div>
          )}
          {selected === 4 && (
            <div className='relative'>
              <SelectProvider setstep={() => setSelected(5)} />
            </div>
          )}
          {selected === 5 && (
            <div className='relative'>
              <PickupAndDelivery />
            </div>
          )}
          {selected === 6 && (
            <div className='relative'>
              <PaymentMethod />
            </div>
          )}
          {selected === 7 && (
            <div className='relative'>
              <OrderSummary setstep={() => setSelected(8)} />
            </div>
          )}
          {selected === 8 && (
            <div className='relative'>
              <OrderConfirmation />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
