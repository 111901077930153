import { toast } from 'react-hot-toast';
import { getPostRequest, getRequest, postRequest, putRequest } from '../../utils/axios';

export const getServicesAndPricingApi = async (addressid) => {
  try {
    const response_data = await getRequest(`/accounts/client/services/` + addressid);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getPromotionsApi = async (addressid) => {
  try {
    const response_data = await getRequest(`/accounts/client/promotions/` + addressid);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getTOSApi = async () => {
  try {
    const response_data = await getRequest(`/accounts/client/tenant_terms_of_service`);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getSupportApi = async () => {
  try {
    const response_data = await getRequest(`/accounts/client/tenant_data_support`);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getPrivacyApi = async () => {
  try {
    const response_data = await getRequest(`/accounts/client/tenant_privacy`);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const getBillingDetailsApi = async (id) => {
  try {
    const response_data = await getRequest(`/accounts/client/billing_details/` + id);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong 1');
    return error?.response?.data;
  }
};
export const updateBillingDetailsApi = async (data) => {
  try {
    const response_data = await putRequest(`/accounts/client/payment_details`, data);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getActiveSubscriptionsApi = async (addressid) => {
  try {
    const response_data = await postRequest(`/orders/client/subscriptions`, addressid);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const updateNotificationsApi = async (data) => {
  try {
    const response_data = await putRequest(`/accounts/client/notifications`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const addPaymentMethodApi = async (addressid, payload) => {
  try {
    const response_data = await getPostRequest(`/accounts/client/payment_method/` + addressid, payload);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const deactivateAcccountApi = async (data) => {
  try {
    const response_data = await putRequest(`/accounts/client/deactivate`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const activateAcccountApi = async (data) => {
  try {
    const response_data = await putRequest(`/accounts/client/reactivate`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const getGiftCardsApi = async () => {
  try {
    const response_data = await getRequest(`/transactions/client/gift_cards`);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const addGiftCardApi = async (data) => {
  try {
    const response_data = await postRequest(`/transactions/client/gift_card/add`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const forgotPasswordApi = async (data) => {
  try {
    const response_data = await postRequest(`/auth/client/forgot_password`, data);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const payInvoiceApi = async (invoice_id, amount) => {
  try {
    const response_data = await getPostRequest(`/transactions/client/invoices/${invoice_id}/pay`, amount);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const getGoogleAuthUrl = async (tenantName, redirectUrl) => {
  if (tenantName == 'laundrycare') {
    tenantName = 'Laundry care';
  } else if (tenantName == 'pushlaundry') {
    tenantName = 'Push laundry';
  }
  try {
    const response_data = await getRequest(
      `/auth/client/google/oauth_url?tenant_name=${tenantName}&redirect_url=${redirectUrl}`
    );
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const signinThroughGoogle = async (tenantName, code, redirect_url) => {
  if (tenantName == 'laundrycare') {
    tenantName = 'Laundry care';
  } else if (tenantName == 'pushlaundry') {
    tenantName = 'Push laundry';
  }
  try {
    const response_data = await postRequest(
      `/auth/client//google/login?tenant_name=${tenantName}&redirect_url=${redirect_url}`,
      code
    );
    if (response_data?.access_token) {
      let authData = JSON.parse(localStorage.getItem('auth'));
      if (authData) {
        authData.access_token = response_data?.access_token;
        localStorage.setItem('auth', authData);
      }
    }
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const createOTP = async (data) => {
  try {
    const response_data = await postRequest(`/auth/client/create_otp`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const validateOTP = async (data) => {
  try {
    const response_data = await postRequest(`/auth/client/validate_otp`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const resetCredentials = async (data) => {
  try {
    const response_data = await postRequest(`/auth/client/reset_credentials`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
