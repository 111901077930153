import React, { useState } from 'react';
import ModalBasic from '../../../components/Modal';
import { useForm } from 'react-hook-form';
import ClientButton from '../../../components/ClientButton';
import { useDispatch } from 'react-redux';
import { updateProfileApi } from '../../../redux/Reducers/MyAccountReducer';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { accountTypeFormatFn } from '../../../utils/commonUtil';
import Button from '../../../components/Button';
export default function EditAccountModal({ modalOpen, setModalOpen, data }) {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm();
  const onSubmit = (newData, e) => {
    if (!isDirty) {
      toast.error('No changes made!');
      return;
    }
    setloading(true);
    const create = updateProfileApi(newData);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setloading(false);
        toast.success('Account Updated Successfully');
        dispatch(getProfileDataApi(false));
        setModalOpen(false);
      } else {
        setloading(false);
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };
  return (
    <ModalBasic
      id='new-account-edit-modal'
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={'Edit Account Info'}
      width='md'
    >
      <div className=''>
        <div className='z-0 rounded-lg bg-white '>
          {loading ? (
            <Loader text='processing...' classes='h-40' />
          ) : (
            <div className='p-5'>
              <div className='flex space-x-2'>
                <div className='relative mb-4 w-full flex-row space-y-1.5'>
                  <label className='go-label-16 mb-1 block' htmlFor='label'>
                    Organization Name
                  </label>
                  <input
                    name='business_name'
                    className={`go-inputtext-16`}
                    {...register('business_name', { required: true })}
                    type='text'
                    defaultValue={data?.business_name}
                  />
                  {errors.business_name?.message && (
                    <p className='go-textnormal-14 text-red-500'>{errors.business_name?.message}</p>
                  )}
                </div>
              </div>
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Account Type</div>
                <input
                  id='label'
                  className='go-inputtext-16 disabled:bg-indigo-50'
                  type='text'
                  defaultValue={accountTypeFormatFn(data?.account_type)}
                  disabled
                />
              </div>
            </div>
          )}

          <div className='flex space-x-4 p-5'>
            <Button
              variant='secondary'
              text='Cancel'
              classes='w-full'
              onClick={(e) => {
                setModalOpen(false);
              }}
            />
            <Button
              variant='primary-dark'
              text='Save'
              classes='w-full'
              onClick={(e) => {
                handleSubmit(onSubmit)(e);
              }}
            />
          </div>
        </div>
      </div>
    </ModalBasic>
  );
}
