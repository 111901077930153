import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationsApi } from '../../../redux/Reducers/commonApis';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';

export default function OrgNotifications({ accountType }) {
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const [intitalRender, setIntitalRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState({
    email: [
      {
        name: 'New Orders',
        data: [
          {
            id: 'new_order_email',
            type: accountType == 'ORGANIZATION_MEMBER' ? 'Member' : 'Admin',
            active: profileData?.new_order_email_notification || false,
          },
          {
            id: 'member_new_order_email',
            type: 'Member',
            active: profileData?.members?.[0]?.new_order_email_notification || false,
          },
        ],
      },
      {
        name: 'Pickup Reminder',
        data: [
          {
            id: 'pickup_reminder_email',
            type: accountType == 'ORGANIZATION_MEMBER' ? 'Member' : 'Admin',
            active: profileData?.pickup_reminder_email_notification || false,
          },
          {
            id: 'member_pickup_reminder_email',
            type: 'Member',
            active: profileData?.members?.[0]?.pickup_reminder_email_notification || false,
          },
        ],
      },
      {
        name: 'Delivered Orders',
        data: [
          {
            id: 'delivered_orders_email',
            type: accountType == 'ORGANIZATION_MEMBER' ? 'Member' : 'Admin',
            active: profileData?.delivered_orders_email_notification || false,
          },
          {
            id: 'member_delivered_orders_email',
            type: 'Member',
            active: profileData?.members?.[0]?.delivered_orders_email_notification || false,
          },
        ],
      },
    ],
  });

  const onEmailSwitchChange = (name, id, status) => {
    setIntitalRender(false);
    setNotificationData((prev) => {
      return {
        ...prev,
        email: prev?.email?.map((item) => {
          if (item?.name === name) {
            return {
              ...item,
              data: item?.data?.map((elm) => {
                if (elm?.id === id) {
                  return {
                    ...elm,
                    active: !status,
                  };
                }
                return elm;
              }),
            };
          }
          return item;
        }),
      };
    });
  };
  useEffect(() => {
    !intitalRender && updateNotificationsFn();
  }, [notificationData]);

  const updateNotificationsFn = () => {
    setLoading(true);
    const payload = {};
    notificationData?.email?.map((item) => {
      item?.data?.map((elm) => {
        payload[elm?.id] = elm?.active;
      });
    });

    const create = updateNotificationsApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setLoading(false);
        toast.success('Successfully updated');
        dispatch(getProfileDataApi());
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader text='processing...' classes='h-60' />
      ) : (
        <div className=''>
          <div className='z-0 rounded-lg bg-white '>
            <header className=' items-center border-b border-gray-100 p-6'>
              <h5 className='go-textnormal-18 my-1 flex-1'>Email Notifications</h5>
              {accountType == 'ORGANIZATION_MEMBER' && (
                <p className='text-sm text-red-500'>
                  To make changes to your notification settings please contact the administrator of your account.
                </p>
              )}
            </header>
            {notificationData?.email?.map((item, index) => (
              <div className='border-b border-gray-100 p-6' key={index}>
                <div className='flex'>
                  <p className='go-label-16 mb-2'>{item.name}</p>
                </div>
                {item?.data?.map((elm, index) => (
                  <div className='flex items-center justify-between' key={index}>
                    <div className='go-textblack-16 my-1.5'>{elm.type}</div>
                    <div
                      className={
                        'form-switch ' + (accountType == 'ORGANIZATION_MEMBER' && ' pointer-events-none opacity-50')
                      }
                    >
                      <input
                        type='checkbox'
                        id={elm?.id}
                        className='sr-only'
                        checked={elm.active}
                        onChange={(e) => onEmailSwitchChange(item.name, elm.id, elm.active)}
                      />
                      <label className='bg-slate-400' htmlFor={elm?.id}>
                        <span className='bg-white shadow-sm' aria-hidden='true'></span>
                        <span className='sr-only'>Switch label</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
