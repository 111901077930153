import { GET_PROFILE_DATA, PROFILE_LOADING } from '../ActionsTypes';
import { getRequest, postRequest } from '../../utils/axios';
import toast from 'react-hot-toast';
const initialState = {
  profileData: null,
  profileLoading: false,
};
const prefix = import.meta.env.VITE_API_PREFIX;
export const PaymentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROFILE_DATA:
      return { ...state, profileData: payload };
    case PROFILE_LOADING:
      return { ...state, profileLoading: payload };

    default:
      return state;
  }
};

export const getMyInvoicesApi = async (filters) => {
  try {
    const response_data = await postRequest(`/transactions/client/invoices`, filters);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getInvoiceFiltersApi = async (data) => {
  try {
    const response_data = await postRequest(`/transactions/client/invoices/filters`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const getInvoiceDetailsbyID = async (id) => {
  try {
    const response_data = await getRequest(`/transactions/client/invoices/` + id);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const downloadInvoicebyID = async (id) => {
  const authUserToken = JSON.parse(localStorage.getItem('auth'));
  try {
    const response = await fetch(`${prefix}/transactions/client/invoices/${id}/pdf`, {
      method: 'GET',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
        Authorization: `${authUserToken?.access_token}`,
      },
    });
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `invoice_${id}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    toast.error(error?.message || 'Something went wrong');
  }
};
