import { toast } from 'react-hot-toast';
import { SET_ORDER_TYPE, SET_ONE_TIME_ORDER, SELECTED_SERVICES, SET_ORDER_DATA } from '../ActionsTypes';
import { getPostRequest, getRequest, postRequest } from '../../utils/axios';
import moment from 'moment';
const initialState = {
  oneTimeOrder: {},
  pickup_dates: null,
  servicesSelected: null,
  selectedServices: null,
  availableSchedules: null,
  availableServices: null,
  orderServiceAddress: null,
  delivery_dates: null,
  orderTimeSlots: null,
  orderProvider: null,
  pickup_times: null,
  delivery_times: null,
  orderPromoCode: null,
  orderPreferences: null,
  orderRepeatFrequency: { value: 'WEEK' },
  newOrderId: null,
  newSubscriptionId: null,
  orderSummary: null,
  final_tip: null,
  orderIsRush: { is_rush: false },
  editOrderIsRush: { is_rush: false },
  orderPaymentDetails: null,
};
const timeZoneGuess = moment.tz.guess();
export const CreateOrderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ORDER_TYPE:
      return { ...state, orderType: payload };
    case SET_ONE_TIME_ORDER:
      return {
        ...state,
        oneTimeOrder: {
          ...state.oneTimeOrder,
          ...payload,
        },
      };
    case SELECTED_SERVICES:
      return { ...state, selectedServices: payload };
    case SET_ORDER_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const getOrderSummary = async (data = {}) => {
  try {
    const response_data = await postRequest(`/orders/client/orders/summary`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.message || 'Something went wrong1');
    return error?.response?.data;
  }
};
export const createNewOrder = async (data = {}, timeZone = timeZoneGuess, type) => {
  try {
    const response_data = await postRequest(`/orders/client/orders/create?&type=${type}`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.message || 'Something went wrong1');
    return error?.response?.data;
  }
};
export const createNewSubscriptions = async (data = {}, timeZone = timeZoneGuess, type) => {
  try {
    const response_data = await postRequest(`/orders/client/subscriptions/create?&type=${type}`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.message || 'Something went wrong1');
    return error?.response?.data;
  }
};
export const getPickupTimeApi = async (workerId, addressId, timeZone = timeZoneGuess, type) => {
  try {
    const response_data = await getRequest(
      `/orders/client/orders/available_schedule/${workerId}/${addressId}?&type=${type}`
    );
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getDeliveryTimeApi = async (workerId, addressId, data = {}, timeZone = timeZoneGuess, type) => {
  try {
    const response_data = await getPostRequest(
      `/orders/client/orders/delivery_times/${workerId}/${addressId}?&type=${type}`,
      data
    );
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const resetCreateOrderFlow = () => {
  return {
    type: SET_ORDER_DATA,
    payload: initialState,
  };
};
