import React, { useCallback, useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import AddIcon from '../../../components/Icons/AddIcon';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import DeleteMinus from '../../../components/Icons/DeleteMinus';
import { isEmpty } from 'lodash';
import { setOneTimeOrderAction, setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { useDispatch, useSelector } from 'react-redux';
import { amountCheckFn, numberConverter } from '../../../utils/commonUtil';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
function Services({ availableServices, servicesLoading }) {
  const tenant = import.meta.env.VITE_TENANT_NAME;
  const dispatch = useDispatch();
  const { orderPreferences, selectedServices } = useSelector((state) => state.createNewOrder);
  const { preferences } = useSelector((state) => state.myaccount.orderPreferences);
  const [selectedServicesState, setSelectedServicesState] = useState(selectedServices);
  const [currentPreferences, setCurrentPreferences] = useState(orderPreferences || preferences || '');
  const onDefaultQuantiyChange = (id) => {
    if (tenant === 'pushlaundry') {
      switch (id) {
        case 13:
          return 15;
        default:
          return 1;
      }
    } else {
      return 1;
    }
  };
  const isServiceSelected = (id) => selectedServices?.some((service) => service?.service_id === id);
  const onSelectService = (serviceSelected) => {
    if (selectedServices?.find((service) => service?.service_id === serviceSelected?.id)) {
      if (isServiceSelected(serviceSelected?.id)) {
        const newServices = selectedServices?.filter((service) => service?.service_id !== serviceSelected?.id);
        dispatch(setOrderDataAction({ selectedServices: newServices }));
      } else {
        const newService = {
          id: uuidv1(),
          service_id: serviceSelected?.id,
          quantity: onDefaultQuantiyChange(serviceSelected?.id),
        };
        dispatch(setOrderDataAction({ selectedServices: [...selectedServices, newService] }));
      }
    } else {
      dispatch(
        setOrderDataAction({
          selectedServices: [
            ...selectedServices,
            { service_id: serviceSelected?.id, quantity: onDefaultQuantiyChange(serviceSelected?.id) },
          ],
        })
      );
    }
  };
  const onIncrementorDecrementFn = (type, serviceListId) => {
    const newServices = selectedServices?.map((service) => {
      if (service?.service_id === serviceListId) {
        if (type === 'increment') {
          return { ...service, quantity: service?.quantity + 1 };
        } else if (type === 'decrement' && service?.quantity > 1) {
          return { ...service, quantity: service?.quantity - 1 };
        }
      }
      return service;
    });
    dispatch(setOrderDataAction({ selectedServices: newServices }));
  };
  const onQuanityChangeFn = (text, serviceListId) => {
    const quantity = numberConverter(text);
    if (quantity === 0) {
      const newServices = selectedServices?.filter((service) => service?.service_id !== serviceListId);
      dispatch(setOrderDataAction({ selectedServices: newServices }));
      return;
    } else {
      //serviceListId is in selectedServices array return with service and add quantity
      if (selectedServices?.some((service) => service?.service_id === serviceListId)) {
        const newServices = selectedServices?.map((service) => {
          if (service?.service_id === serviceListId) {
            return { ...service, quantity };
          }
          return service;
        });
        dispatch(setOrderDataAction({ selectedServices: newServices }));
      } else {
        const newService = {
          id: uuidv1(),
          service_id: serviceListId,
          quantity,
        };
        dispatch(setOrderDataAction({ selectedServices: [...selectedServices, newService] }));
      }

      // const newServices = selectedServices?.map((service) => {
      //   if (service?.service_id === serviceListId) {
      //     return { ...service, quantity };
      //   }
      //   return service;
      // });
      // dispatch(setOrderDataAction({ selectedServices: newServices }));
    }
  };
  useEffect(() => {
    if (!isEmpty(availableServices)) {
      if (!isEmpty(selectedServices)) {
        setSelectedServicesState(selectedServices);
      } else {
        const newService = {
          id: uuidv1(),
          service_id: availableServices && availableServices[0]?.id,
          quantity: onDefaultQuantiyChange(availableServices[0]?.id),
        };
        setSelectedServicesState([newService]);
      }
    }
  }, [availableServices]);

  useEffect(() => {
    dispatch(setOrderDataAction({ selectedServices: selectedServicesState, orderIsRush: { value: false } }));
  }, [selectedServicesState]);

  useEffect(() => {
    dispatch(setOrderDataAction({ orderPreferences: currentPreferences }));
  }, [currentPreferences]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div className='col-span-full flex flex-col  sm:col-span-8'>
      {/* <header className='flex items-center pb-3'>
        <h5 className='go-heading-18'>Services</h5>
      </header>
      <hr className='go-hr-dashed '></hr> */}
      {servicesLoading ? (
        <Loader text='loading services...' classes=' h-96' bg={true} />
      ) : availableServices?.length === 0 ? (
        <NoData text='No services available' classes='h-96' />
      ) : (
        <section>
          <div className=' grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6'>
            {availableServices?.map((service) => (
              <div
                key={service?.id}
                onClick={() => onSelectService(service)}
                className={classNames(
                  isServiceSelected(service?.id) ? 'border-client-300' : 'border-white',
                  ' border-3 relative flex cursor-pointer justify-between rounded-lg bg-white p-2'
                )}
              >
                <div className='flex flex-row'>
                  <div className='flex w-6'>
                    <input
                      type='checkbox'
                      id={service?.id}
                      name={service?.id}
                      onChange={() => onSelectService(service)}
                      checked={isServiceSelected(service?.id)}
                      className={classNames(
                        isServiceSelected(service?.id) ? 'text-client-300' : 'text-slate-200',
                        ' h-4 w-4 rounded-full ring-0'
                      )}
                    />
                  </div>
                  {/* {!isServiceSelected(service?.id) && ( */}
                  <div className='flex w-14 justify-center'>
                    <img src={service?.image} alt={service?.service} className='h-12 w-12' />
                  </div>
                  {/* )} */}
                  <div className='flex flex-col space-y-1'>
                    <label className='go-textblack-15 cursor-pointer'>{service?.service}</label>
                    <span className='go-textnormal-13'>{`${amountCheckFn(service?.client_fee)} per ${
                      service?.unit
                    }`}</span>
                    {isServiceSelected(service?.id) && (
                      <div className='mt-2 flex'>
                        {/* <MinusCircleIcon
                          className='mr-2 h-5 w-5'
                          onClick={(e) => {
                            e.stopPropagation();
                            onIncrementorDecrementFn('decrement', service?.id);
                          }}
                        /> */}

                        {/* <PlusCircleIcon
                          className='ml-2 h-5 w-5'
                          onClick={(e) => {
                            e.stopPropagation();
                            onIncrementorDecrementFn('increment', service?.id);
                          }}
                        /> */}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex flex-col items-center'>
                  <input
                    type='number'
                    id={service?.id}
                    className='go-inputtext-15 h-10 w-12 p-1 text-center'
                    value={selectedServices?.find((s) => s?.service_id === service?.id)?.quantity?.toString() || ''}
                    onChange={(e) => onQuanityChangeFn(e?.target?.value, service?.id)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <span className='go-textnormal-10'>Quantity</span>
                </div>
                {/* {isServiceSelected(service?.id) && (
                  <div className='flex grow items-end justify-end'>
                    <img src={service?.image} alt={service?.service} className='h-20  ' />
                  </div>
                )} */}
              </div>
            ))}
          </div>
          {isEmpty(selectedServices) && (
            <p className='mt-4 text-center text-red-500'>Please select at least one service</p>
          )}
          <hr className='go-hr-dashed mt-8 mb-5 hidden sm:block'></hr>
          <div className='grid gap-0 sm:gap-4'>
            <div className=''>
              <label htmlFor='preferences' className='go-label-16'>
                Preferences
              </label>
              <textarea
                id='preferences'
                className='form-textarea go-inputtext-16'
                rows='4'
                required
                value={currentPreferences}
                onChange={(e) => setCurrentPreferences(e?.target?.value)}
                onBlur={(e) => setCurrentPreferences(e?.target?.value)}
              ></textarea>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Services;
