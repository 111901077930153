import moment from 'moment-timezone';
import { ordinal_suffix_of } from '../components/Utils';

const userTimeZone = moment.tz.guess();
const timeZoneFormat = moment.tz(userTimeZone).format('z');
const getTimeZoneAbbr = (date, timezone) => {
  const momentDate = moment.tz(date, timezone);
  const tzAbbreviation = momentDate.format('z');
  return tzAbbreviation;
};
const dateFn = (type, date, time1, time2, timezone = userTimeZone) => {
  let date1 = '';
  let date2 = '';
  if (date?.length == 10) {
    if (type == 'toLocal' || type == 'toTZWithSeparator' || type == 'toTZOnlyDate' || type == 'toTZOnlyTimes') {
      date1 = date + 'T' + time1;
      date2 = date + 'T' + time2;
    } else if (type == 'toLocalOnlyTime') {
      date1 = date + 'T' + time1;
    } else {
      date1 = date + 'T12:00:00Z';
    }
  } else if (date?.length > 9) {
    date1 = date + 'Z';
  }
  switch (type) {
    case 'type1':
      return moment(date1).format('MMM DD, YYYY, h:mm a'); //April 16, 2023, 11:59 pm
    case 'type2':
      return moment(date1).format('MMM DD, YYYY'); //April 16, 2023
    case 'type3':
      return moment(date1).format('ddd, MMM DD'); //Thursday, September 16
    case 'type4':
      return moment(date1).format('ddd, MMM DD'); //Thu, Sep 16
    case 'type5':
      return moment(date1).format('ddd, MMM DD, YYYY'); //Thursday, Sep 16, 2021
    case 'type6':
      return moment(date1).format('ddd, MMM DD'); //Thursday, Sep 16
    case 'type7':
      return moment(date1).format('ddd'); //Thu
    case 'type8':
      return moment(date1).format('MMM DD'); //Sep 16
    case 'MM/DD/YYYY':
      return moment(date1).format('MM/DD/YYYY'); //03/06/2021
    case 'MMDDYYYY':
      return moment(date1).format('MMDDYYYY'); //03062021
    case 'YYYYMMDD':
      return moment(date1).format('YYYYMMDD'); //20210306
    case 'YYYY-MM-DD':
      return moment(date1).format('YYYY-MM-DD'); //2021-03-06
    case 'day':
      return moment(date1).format('dddd'); //Monday
    case 'day1':
      return ordinal_suffix_of(moment(date1).format('D'));
    case 'timeLocal':
      return moment(date1).format('h:mm a'); //4:00pm
    case 'timeUTC':
      return moment(date1).format('h:mm a'); //11:00 am
    case 'difference':
      return moment(date1).diff(moment(time1), 'days'); //4
    case 'daysBetween':
      const days = moment(date1).diff(moment(), 'days'); //4
      return days < 0 ? days * -1 : days; //4
    case 'toLocal':
      const val = moment(date1).format('ddd, MMM DD');
      const t1 = utcTimeToLocalTime(date1);
      const t2 = utcTimeToLocalTime(date2);
      const formattedDate = `${val}, ${t1} - ${t2} (${getTimeZoneAbbr(date1, timezone)})`;
      return formattedDate; //Thursday, September 16, 4:00 pm - 5:00 pm EST
    case 'toTZWithSeparator':
      const val1 = moment(date1).format('ddd, MMM DD');
      const t11 = utcTimeToLocalTime(date1);
      const t21 = utcTimeToLocalTime(date2);
      const formattedDate1 = `${val1} • ${t11} - ${t21} (${getTimeZoneAbbr(date1, timezone)})`;
      return formattedDate1;
    case 'toTZOnlyDate':
      return moment(date1).format('ddd, MMM DD');
    case 'toTZOnlyTimes':
      const t32 = utcTimeToLocalTime(date1);
      const t33 = utcTimeToLocalTime(date2);
      const formattedDate34 = `${t32} - ${t33} (${getTimeZoneAbbr(date1, timezone)})`;
      return formattedDate34;
    case 'toLocalOnlyTime':
      const t3 = utcTimeToLocalTime(date1);
      return t3; //4:00 pm
    default:
      return moment(date1).format('MMMM DD, YYYY, h:mm a');
  }
};
const getDifference = (date1, date2) => {
  const a = moment(date1);
  const b = moment(date2);
  const diff = b.diff(a, 'days');
  return diff;
};
const addDaysAndGetWeekday = (date, days) => {
  const newDate = moment(date).add(days, 'days').format('dddd'); //Monday
  return newDate;
};
const utcTimeToLocalTime = (dateTime, showTimeZone = false) => {
  let dateTimeNew = dateTime + 'Z';
  // const isDst = moment(dateTimeNew).isDST();
  // if (isDst) {
  //   let time = dateTime.split('T')[1];
  //   return moment(`1970-01-01T${time}Z`).format('h:mm a');
  //   // return moment(dateTime + '')
  //   //   .subtract(1, 'hours')
  //   //   .format('h:mm a');
  // }
  return `${moment(dateTimeNew).format('h A')}${showTimeZone ? ` ${getTimeZoneAbbr(dateTimeNew, userTimeZone)}` : ''}`;
};
export { getDifference, addDaysAndGetWeekday, utcTimeToLocalTime, dateFn };
