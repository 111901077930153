import React from 'react';
import ClientButton from '../../../components/ClientButton';
import subscriptionImg from '../../../images/subscription-main.png';
export default function SubscriptionsDefault() {
  return (
    <div>
      <header className='flex items-center border-b border-gray-100 p-6'>
        <h5 className='go-textnormal-18 my-1 flex-1'>Subscription</h5>
        <div className='flex'>
          <button className='go-btn-primary mr-2'>
            {/* <EditIcon
              onClick={() => {
                setEditPayment(true);
              }}
            /> */}
          </button>
        </div>
      </header>
      <div className='flex-row px-56 py-2 text-center'>
        <p className='go-text-semibold-20 w-full py-5 text-center'>Create a new subscription today!</p>
        <p className='go-textblack-16 w-full pb-5 text-center'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </p>
        <img src={subscriptionImg} alt='subscription' className='mx-auto w-1/2' />
        <div className='py-5'>
          <ClientButton variant='secondary' text='Maybe later' size='small' />
          <ClientButton variant='primary' text='Lets do it' size='small' />
        </div>
      </div>
    </div>
  );
}
