import React, { useEffect, useState } from 'react';
import giftcardsvg from '../../../images/giftcard-svg.svg';
import Loader from '../../../components/Loader';
import { addGiftCardApi, getGiftCardsApi } from '../../../redux/Reducers/commonApis';
import { toast } from 'react-hot-toast';
import { amountCheckFn } from '../../../utils/commonUtil';
import ModalBasic from '../../../components/Modal';
import Button from '../../../components/Button';

export default function GiftCardModal({ modalOpen, setModalOpen, billingDetails, reFetchBillingDetails }) {
  const [loading, setLoading] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardNumber, setGiftCardNumber] = useState(null);
  const [giftCardErrorMsg, setGiftCardErrorMsg] = useState(null);
  const giftCardTotal = () => {
    let total = 0;
    giftCards?.map((item) => (total = total + item?.amount));
    return `$${total}`;
  };
  const getGiftCardFn = (id) => {
    setLoading(true);
    const response = getGiftCardsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setGiftCards(res?.data || {});
        setLoading(false);
      } else {
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };
  const addNewGiftCardFn = () => {
    if (!giftCardNumber) {
      toast.error('Please enter gift card number');
      setGiftCardErrorMsg('Please enter gift card number');
      return;
    }
    setLoading(true);
    const data = {
      gift_card_code: giftCardNumber,
    };
    const response = addGiftCardApi(data);
    response.then((res) => {
      setGiftCardErrorMsg(null);
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.message || 'Gift card added successfully');
        setGiftCardNumber(null);
        getGiftCardFn();
        setLoading(false);
        reFetchBillingDetails();
      } else {
        toast.error(res?.message || 'Something went wrong');
        setGiftCardErrorMsg(res?.message || 'Invalid gift card number');
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getGiftCardFn();
  }, []);

  return (
    <ModalBasic id='gift-card-modal' modalOpen={modalOpen} setModalOpen={setModalOpen} title={'Gift Cards'} width='md'>
      <>
        <div className='z-0 rounded-lg bg-white'>
          {/* <header className='flex items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Gift Cards</h5>
          </header> */}
          <div className='p-5'>
            <div className='flex-row'>
              <div className='rounded-lg '>
                {loading ? (
                  <Loader classes='h-60' />
                ) : (
                  <div className='space-y-5 '>
                    <div className='flex space-x-4'>
                      <div className='w-1/2 text-right'>
                        <img src={giftcardsvg} alt='gift card' className='float-right w-20' />
                      </div>
                      <div className='flex items-center'>
                        <div>
                          <div className='go-label-16'>Balance</div>
                          <div className='go-textblackbold-24'>
                            {amountCheckFn(billingDetails?.available_gift_card_balance)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex space-x-4'>
                      <table className='w-full'>
                        <thead className='go-label-16 text-left'>
                          <tr>
                            <th className='go-textnormal-16'>Redeemed Gift Cards</th>
                            <th className='go-textnormal-16'>Value</th>
                          </tr>
                        </thead>
                        <tbody className=''>
                          {giftCards?.map((item, idx) => (
                            <tr key={idx}>
                              <td className='go-textblackmedium-16 py-1.5'>{item?.code}</td>
                              <td className='go-textblackmedium-16 py-1.5'>{amountCheckFn(item?.amount)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className='flex space-x-4 pt-10'>
                      <div className='flex-1'>
                        <label className='go-label-16' htmlFor='gift-card'>
                          Add a Gift Card
                        </label>
                        <input
                          id='gift-card'
                          className='go-inputtext-16'
                          type='text'
                          placeholder='Enter gift card number'
                          defaultValue={giftCardNumber}
                          onChange={(e) => {
                            setGiftCardErrorMsg(null);
                            setGiftCardNumber(e.target.value);
                          }}
                        />
                        {giftCardErrorMsg && <div className='text-red-500'>{giftCardErrorMsg}</div>}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='flex space-x-4 p-5'>
            <Button variant='secondary' text='Cancel' classes='w-full' onClick={() => setModalOpen(false)} />
            <Button variant='primary-dark' text='Save' classes='w-full' onClick={addNewGiftCardFn} />
          </div>
        </div>
      </>
    </ModalBasic>
  );
}
