import React, { useState } from 'react';
import { selectedServices } from '../../../mockdata/common';
import { useNavigate } from 'react-router-dom';

import no_picture from '../../../images/no-profile-picture.png';

import ClientButton from '../../../components/ClientButton';
import { Route } from 'react-router-dom';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { useSelector } from 'react-redux';
import { CalendarIcon } from '@heroicons/react/24/outline';
export default function OrderConfirmation({ setstep }) {
  const navigate = useNavigate();
  const { orderType, orderTimeSlots, orderProvider, newOrderId, orderSummary, newSubscriptionId, orderServiceAddress } =
    useSelector((state) => state.createNewOrder);
  return (
    <div className='max-w-9xl mx-auto w-full px-1 py-1 sm:px-6 lg:px-8'>
      <div className='m-auto max-w-xl'>
        <div className=' bg-white'>
          <section className='border-b border-gray-100  py-6 px-6 sm:py-10 sm:px-14'>
            {orderProvider && (
              <div className='flex items-center'>
                <div className='mr-4'>
                  <img
                    className='h-20 w-20 rounded-full'
                    src={orderProvider?.profile_image?.absolute_url || no_picture}
                    width='80'
                    height='80'
                    alt='User upload'
                  />
                </div>
                <p className='go-textblacknormal-24 '>
                  {orderProvider?.first_name} {orderProvider?.last_name}
                </p>
              </div>
            )}
            <div className='flex  items-center pt-4'>
              <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <g fill='#28C2B3' fillRule='nonzero'>
                  <path d='M9.71 11.29a1.004 1.004 0 1 0-1.42 1.42l3 3A1 1 0 0 0 12 16a1 1 0 0 0 .72-.34l7-8a1 1 0 0 0-1.5-1.32L12 13.54l-2.29-2.25z' />
                  <path d='M21 11a1 1 0 0 0-1 1 8 8 0 0 1-8 8A8 8 0 0 1 6.33 6.36 7.93 7.93 0 0 1 12 4a8.79 8.79 0 0 1 1.9.22 1 1 0 1 0 .47-1.94A10.54 10.54 0 0 0 12 2a10 10 0 0 0-7 17.09A9.93 9.93 0 0 0 12 22c5.523 0 10-4.477 10-10a1 1 0 0 0-1-1z' />
                </g>
              </svg>
              {orderType === 'oneTime' ? (
                <span className='go-heading-24 pl-2 font-bold'>Order placed, thanks!</span>
              ) : (
                <span className='go-heading-24 pl-2 font-bold'>Subscription Created!</span>
              )}
            </div>
            <div className='flex py-1 pl-8'>
              <p className='go-textblacknormal-20'>A confirmation will be sent to your email.</p>
            </div>
            <div className='flex py-1 pl-8'>
              {orderType === 'oneTime' ? (
                <p className='go-link-20 '>Order #{newOrderId && newOrderId}</p>
              ) : (
                <p className='go-link-20 '>Subscription #{newSubscriptionId && newSubscriptionId}</p>
              )}
            </div>
            {orderType != 'oneTime' && (
              <div className='pt-10 pl-8'>
                <p className='go-label-16'>First Pickup:</p>
                <p className='go-textblacknormal-16'>
                  {dateFn(
                    'toLocal',
                    orderTimeSlots?.first_pickup_date,
                    orderTimeSlots?.pickup_start_time,
                    orderTimeSlots?.pickup_end_time,
                    orderServiceAddress?.time_zone
                  )}
                </p>
              </div>
            )}
          </section>
          <section className='relative border-b border-gray-100  py-4 px-4 sm:py-6 sm:px-14'>
            <div className='mx-auto px-4 py-4'>
              <div className='absolute left-6 mt-4  h-14 sm:left-14 sm:h-16'>
                <div>
                  <div className='border-client-link flex h-3 w-3 items-center justify-center rounded-full border'></div>
                </div>
                <div className='bg-client-link ml-1.5 h-full w-px' />
                <div>
                  <div className='border-client-link bg-client-link flex h-3 w-3 items-center justify-center rounded-full border'></div>
                </div>
              </div>
              <div className='ml-4'>
                <div className='pb-8'>
                  <div className='flex'>
                    <div>
                      <div className='flex h-9 w-9 items-center justify-center rounded bg-[#EEF4FE]'>
                        <CalendarIcon className='text-client-primarylight h-5 w-5' />
                      </div>
                    </div>
                    <div className='pl-2'>
                      <p className='go-label-16'>Pick up</p>
                      {orderType === 'oneTime' ? (
                        <p className='go-textblacknormal-16'>
                          {dateFn(
                            'toLocal',
                            orderTimeSlots?.pickup_date,
                            orderTimeSlots?.pickup_start_time,
                            orderTimeSlots?.pickup_end_time,
                            orderServiceAddress?.time_zone
                          )}
                        </p>
                      ) : (
                        <p className='go-textblacknormal-16'>
                          {dateFn(
                            'toLocal',
                            orderTimeSlots?.first_pickup_date,
                            orderTimeSlots?.pickup_start_time,
                            orderTimeSlots?.pickup_end_time,
                            orderServiceAddress?.time_zone
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex'>
                  <div>
                    <div className='flex h-9 w-9 items-center justify-center rounded bg-[#EEF4FE]'>
                      <CalendarIcon className='text-client-primarylight h-5 w-5' />
                    </div>
                  </div>
                  <div className='pl-2'>
                    <p className='go-label-16'>Delivery</p>
                    {orderType === 'oneTime' ? (
                      <p className='go-textblacknormal-16'>
                        {dateFn(
                          'toLocal',
                          orderTimeSlots?.dropoff_date,
                          orderTimeSlots?.dropoff_start_time,
                          orderTimeSlots?.dropoff_end_time,
                          orderServiceAddress?.time_zone
                        )}
                      </p>
                    ) : (
                      <p className='go-textblacknormal-16'>
                        {dateFn(
                          'toLocal',
                          orderTimeSlots?.dropoff_date,
                          orderTimeSlots?.dropoff_start_time,
                          orderTimeSlots?.dropoff_end_time,
                          orderServiceAddress?.time_zone
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='border-b border-gray-100  py-4 px-4 sm:py-6 sm:px-14'>
            <div className='flex-row pl-8'>
              <p className='go-heading-18'>Services</p>
              {orderSummary?.items.map((item, idx) => (
                <div className=' py-3' key={idx}>
                  <div className='go-textblacknormal-16 text-left'>
                    {item?.name} {`(${item?.quantity})`}
                  </div>
                  <div className='text-left text-xs font-normal text-gray-500'>{item?.description}</div>
                </div>
              ))}
            </div>
          </section>
        </div>
        {orderType === 'oneTime' ? (
          <ClientButton
            variant='primary'
            text='View Order'
            classes='w-full py-4 mx-0 mt-2 sm:mt-4'
            onClick={() => navigate(`/order/${newOrderId}`)}
          />
        ) : (
          <ClientButton
            variant='primary'
            text='View Subscription'
            classes='w-full py-4 mx-0  mt-2 sm:mt-4'
            onClick={() => navigate(`/account/`)}
          />
        )}
      </div>
    </div>
  );
}
